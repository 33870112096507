//React
import React, {FC, useEffect, useState} from 'react'

//CLSX - Dynamic CSS Class Creator
import clsx from 'clsx'

//React Router Dom
import {Link} from 'react-router-dom'

// React Hook Form - Yup Validation
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'

//Helpers- Create Public File Path
import {toAbsoluteUrl} from '../../helpers/AssetHelpers'

//Redux ToolKit API
import {useSinginMutation} from '../../api/endpoints/auth'

//Redux - Dispatch - Actions - State
import {useDispatch, useSelector} from 'react-redux'
import {setAuthState} from '../../redux/slice/authSlice'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'



const SingIn: FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  


  const [singin, {isLoading, isError, data}] = useSinginMutation() 

  const schema = Yup.object().shape({
    username: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    password: Yup.string()
      .min(3, intl.formatMessage({id: 'FORM.VALIDATION.MIN_LENGTH_FIELD'}, {name: '3'}))
      .max(50, intl.formatMessage({id: 'FORM.VALIDATION.MAX_LENGTH_FIELD'}, {name: '50'}))
      .required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
  })

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: {errors, touchedFields, isSubmitting, isValid},
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      // username: 'root',
      // password: 'M.taha1998',
      username: '',
      password: '',
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = (formData: any) => {

    const sendFormdata = new FormData();

    Object.keys(formData).forEach(function(key) {
      sendFormdata.append(key,formData[key])
    });
   
   
    singin({
      endpointData: sendFormdata,
      toastSuccessMessageStatus: true,
      toastErrorMessageStatus: true,
    })
  }

  useEffect(() => {
    if (!isLoading && !isError && data !== undefined) {
      console.log(data?.data)
      dispatch(setAuthState({user:data?.data?.user, token:data?.data?.access_token}))
    }
  }, [isLoading, isError, data])

  return (
    <form
      className='form w-100'
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>
          {intl.formatMessage({id: 'SINGIN_PAGE.TITLE'})}
        </h1>
        <div className='text-gray-500 fw-semibold fs-6'>
          {intl.formatMessage({id: 'SINGIN_PAGE.LOGIN_WITH_SOCIAL_MEDIA'})}
        </div>
      </div>
      {/* begin::Heading */}

      {/* begin::Login options */}
      <div className='row g-3 mb-9'>
        {/* begin::Col */}
        <div className='col-md-6'>
          {/* begin::Google link */}
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            {intl.formatMessage({id: 'SINGIN_PAGE.SINGIN_WITH_GOOGLE'})}
          </a>
          {/* end::Google link */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-md-6'>
          {/* begin::Google link */}
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            {intl.formatMessage({id: 'SINGIN_PAGE.SINGIN_WITH_APPLE'})}
          </a>
          {/* end::Google link */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Login options */}

      {/* begin::Separator */}
      <div className='separator separator-content my-14'>
        <span className='w-250px text-gray-500 fw-semibold fs-7'>
          {intl.formatMessage({id: 'SINGIN_PAGE.OR_LOGIN_WITH_USERNAME'})}
        </span>
      </div>
      {/* end::Separator */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({id: 'FORM.LABEL.USERNAME_EMAIL_PHONE'})}
        </label>
        <input
          {...register('username')}
          type='text'
          //placeholder={intl.formatMessage({id: 'FORM.PLACEHOLDER.USERNAME'})}
          autoComplete='off'
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': touchedFields?.username && errors?.username},
            {
              'is-valid': touchedFields?.username && !errors?.username,
            }
          )}
        />
        {touchedFields?.username && errors?.username && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{errors?.username?.message}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>
          {intl.formatMessage({id: 'FORM.LABEL.PASSWORD'})}
        </label>
        <input
          {...register('password')}
          type='password'
          //placeholder={intl.formatMessage({id: 'FORM.PLACEHOLDER.PASSWORD'})}
          autoComplete='off'
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': touchedFields?.password && errors?.password,
            },
            {
              'is-valid': touchedFields?.password && !errors?.password,
            }
          )}
        />
        {touchedFields?.password && errors?.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{errors?.password?.message}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          {intl.formatMessage({id: 'SINGIN_PAGE.FORGOT_PASSWORD'})}
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={isSubmitting || !isValid}
        >
          {!isLoading && (
            <span className='indicator-label'>
              {intl.formatMessage({id: 'FORM.BUTTON.SINGIN'})}
            </span>
          )}
          {isLoading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'MESSAGE.PLEASE_WAITING'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        {intl.formatMessage({id: 'SINGIN_PAGE.DONT_HAVE_ACCOUNT'})}{' '}
        <Link to='/auth/singup' className='link-primary'>
          {intl.formatMessage({id: 'SINGIN_PAGE.SINGUP'})}
        </Link>
      </div>
    </form>
  )
}

export default SingIn
