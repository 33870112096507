//React
import React, {useEffect, useRef, useState} from 'react'

//Chart
import ApexCharts, {ApexOptions} from 'apexcharts'

import {Link} from 'react-router-dom'

//Theme
import {useThemeMode} from '../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

type AppointmentProps = {
  total_credit: number
  remanining_credit: number
  package_name: string
}

type Props = {
  className: string
  appointment: AppointmentProps[] | undefined
}

const Appointment: React.FC<Props> = ({className, appointment}) => {
  const intl = useIntl()
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [series, setSeries] = useState<AppointmentProps[]>([])
  const [total, setTotal] = useState(0)

  console.log(series)

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, series])

  useEffect(() => {
    if (appointment != undefined) {
      setTotal(total)
      setSeries(appointment)
    }
  }, [appointment])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, getChartOptions({intl, series}))
    if (chart) {
      chart.render()
    }

    return chart
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        {/* begin::Title */}
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {intl.formatMessage({id: 'DASHBOARD.CUSTOMER_PACKAGE.TITLE'})}
          </span>
        </h3>
        {/* end::Title */}

        {/* begin::Toolbar */}
        <div className='card-toolbar'>
          {/* begin::Menu */}
          {/* <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 /> */}
          {/* end::Menu */}
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} style={{height: '400px'}} />

        <div className='separator mb-4'></div>
        <div className='d-flex justify-content-center align-items-center'>
          {appointment != undefined && appointment.length > 0 ? (
            <>
              <div
                className='d-inline-block rounded-circle h-15px w-15px me-2'
                style={{backgroundColor: '#3E97FF'}}
              />
              <span className='text-muted fw-semibold me-4'>
                {`${appointment[0]?.package_name}(${appointment[0]?.remanining_credit}/${appointment[0]?.total_credit})`}
              </span>
            </>
          ) : null}
          {appointment != undefined && appointment.length > 1 ? (
            <>
              <div
                className='d-inline-block rounded-circle h-15px w-15px me-2'
                style={{backgroundColor: '#1BC5BD'}}
              />
              <span className='text-muted fw-semibold me-4'>
                {`${appointment[1]?.package_name}(${appointment[1]?.remanining_credit}/${appointment[1]?.total_credit})`}
              </span>
            </>
          ) : null}
          {appointment != undefined && appointment.length > 2 ? (
            <>
              <div
                className='d-inline-block rounded-circle h-15px w-15px me-2'
                style={{backgroundColor: '#7239EA'}}
              />
              <span className='text-muted fw-semibold me-4'>
                {`${appointment[2]?.package_name}(${appointment[2]?.remanining_credit}/${appointment[2]?.total_credit})`}
              </span>
            </>
          ) : null}
        </div>

        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {Appointment}

function getChartOptions({intl, series}): ApexOptions {
  return {
    series: [...series].slice(0, 3).map((s) => {
      const calc = ((s.total_credit - s.remanining_credit) / s.total_credit) * 100
      return Math.round(calc)
    }),
    chart: {
      height: 350,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,

        hollow: {
          margin: 5,
          size: series.length < 2 ? '60%' : series.length < 3 ? '40%' : '30%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: true,
          },
        },
      },
    },

    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: false,
      lineCap: 'round',
    },

    colors: ['#3E97FF', '#1BC5BD', '#7239EA'],
    labels: [...series].slice(0, 3).map((s) => s.package_name),
    legend: {
      show: true,
      floating: true,
      fontSize: '16px',
      position: 'left',
      offsetX: 25,
      offsetY: 15,
      labels: {
        useSeriesColors: true,
      },

      formatter: function (seriesName, opts) {
        return `${
          series[opts.seriesIndex].total_credit - series[opts.seriesIndex].remanining_credit
        } ${intl.formatMessage({id: 'DASHBOARD.CUSTOMER_PACKAGE.CREDIT_SCORE'})}`
      },
      itemMargin: {
        vertical: 3,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
  }
}
