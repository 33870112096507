// createApi
import api from '..'

// paths
import {SUMMARY, MESSAGES} from '../paths'

// helpers - serializeQueryArgs
import serializeQueryArgs from '../helpers/serializeQueryArgs'
import filterQueryUrlCreater from '../helpers/filterQueryUrlCreater'

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    summary: builder.query({
      query: (credentials) => ({
        url: SUMMARY,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: ['SUMMARY'],
    }),
    lecturesPerformanceRates: builder.query({
      query: (credentials) => ({
        url:
          credentials?.endpointData?.personel == undefined
            ? `/lectures-performance-rates?start_date=${credentials?.endpointData?.start_date}&end_date=${credentials?.endpointData?.end_date}`
            : `/lectures-performance-rates?personel=${credentials?.endpointData?.personel}&start_date=${credentials?.endpointData?.start_date}&end_date=${credentials?.endpointData?.end_date}`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: ['SUMMARY'],
    }),
    messages: builder.query({
      query: (credentials) => ({
        url: MESSAGES,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: ['MESSAGES'],
    }),
  }),
})

export const {
  useSummaryQuery,
  useLecturesPerformanceRatesQuery,
  useMessagesQuery,
  useLazyMessagesQuery,
} = extendedApi
