//React
import {useState, useEffect} from 'react'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

//Data Fsn
import {addDays} from 'date-fns'

//Helpers
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'

//Redux - Dispatch - Actions - State
import {useDispatch, useSelector} from 'react-redux'
import {selectInitialData} from '../../redux/slice/initialDataSlice'
import {selectCurrentUser} from '../../redux/slice/authSlice'

//Redux ToolKit API
import {useSummaryQuery, useLecturesPerformanceRatesQuery} from '../../api/endpoints/summary'

//Custom Widget
import {
  GenderChart,
  PackageWidget,
  Statistics,
  IncomeAndExpenseWidget,
  NumberOfLectureByDate,
  RoomUsageRate,
  StoppingRequests,
  InviteFacility,
} from './components'

//Date Format
import {dateFormat} from '../../i18n/Metronici18n'

const AdminDashboard = () => {
  const intl = useIntl()
  const [dateRange, setDataRange] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: 'selection',
  })

  const initialdata = useSelector(selectInitialData)

  const motivations =
    initialdata?.motivations == undefined ? [] : initialdata?.motivations.filter((x) => x.type == 0)

  const {data, isLoading, isFetching, isError} = useSummaryQuery(
    {
      endpointData: {},
      toastSuccessMessageStatus: false,
      toastErrorMessageStatus: true,
    },
    {
      pollingInterval: 60000,
    }
  )

  const currentUser = useSelector(selectCurrentUser)

  const {data: lecturesPerformanceRatesData} = useLecturesPerformanceRatesQuery({
    endpointData: {
      start_date: dateFormat(new Date(dateRange.startDate), 'yyyy-MM-dd'),
      end_date: dateFormat(new Date(dateRange.endDate), 'yyyy-MM-dd'),
    },
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })

  const gender = data?.data?.gender?.customer

  const sale = data?.data?.package?.satin_alinan
  const finish = data?.data?.package?.biten
  const numberOfLectureByDateData = data?.data['tarihlere gore islenen ders sayisi']
  const roomUsageRate = data?.data?.room['Salon Kullanim Agirligi(gunluk/toplam)']
  const accountStatements = data?.data['account-statements']

  return (
    <>
      <div className='row gy-10 gx-xl-10 mb-5'>
        <div className='col-xl-4'>
          <div
            className='card card-xxl-stretch mb-5 d-flex flex-center align-items-center'
            style={{
              backgroundColor: '#3E97FF',
              backgroundImage: `url('${toAbsoluteUrl(`/media/basel/jpeg/motive${currentUser?.company_type}0.jpeg`)}')`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              minHeight: 500,
              padding: 50,
            }}
          >
            <span
              className='text-center fs-2x text-white fw-bold my-10'
              style={{
                textShadow: '1px 1px 2px black',
              }}
            >
              {motivations.length > 0
                ? motivations[Math.floor(Math.random() * motivations.length)].message
                : ''}
            </span>
          </div>
          {/* <GenderChart className='card-xxl-stretch mb-5' gender={gender} /> */}
        </div>

        <div className='col-xl-4'>
          <PackageWidget
            className='card-xl-stretch mb-5'
            title={intl.formatMessage({id: 'DASHBOARD.PACKAGE_SALE.TITLE'})}
            bgPath={`url('${toAbsoluteUrl('/media/basel/svg/satin_alinan_bg.svg')}')`}
            today={sale != undefined && sale['bugun'] != undefined ? sale['bugun'] : 0}
            week={sale != undefined && sale['bu hafta'] != undefined ? sale['bu hafta'] : 0}
            month={sale != undefined && sale['bu ay'] != undefined ? sale['bu ay'] : 0}
            total={sale != undefined && sale['toplam'] != undefined ? sale['toplam'] : 0}
            type='sale'
          />
        </div>
        <div className='col-xl-4'>
          <PackageWidget
            className='card-xl-stretch mb-5'
            title={intl.formatMessage({id: 'DASHBOARD.PACKAGE_FINISH.TITLE'})}
            bgPath={`url('${toAbsoluteUrl('/media/basel/svg/biten_bg.svg')}')`}
            today={finish != undefined && finish['bugun'] != undefined ? finish['bugun'] : 0}
            week={finish != undefined && finish['bu hafta'] != undefined ? finish['bu hafta'] : 0}
            month={finish != undefined && finish['bu ay'] != undefined ? finish['bu ay'] : 0}
            total={finish != undefined && finish['toplam'] != undefined ? finish['toplam'] : 0}
            type='finish'
          />
        </div>
      </div>
      <div className='row gy-10 gx-xxl-10 mb-5'>
        {/* begin::Col */}
        <div className='col-xxl-6 mb-5'>
          <Statistics
            className='card-xxl-stretch mb-5'
            dateRange={dateRange}
            setDataRange={setDataRange}
            series={
              lecturesPerformanceRatesData?.data == undefined
                ? [0, 0, 0]
                : [
                    lecturesPerformanceRatesData?.data?.total_capacity,
                    lecturesPerformanceRatesData?.data?.total_used_capacity,
                    lecturesPerformanceRatesData?.data?.total_passed_capacity,
                  ]
            }
          />
        </div>
        {/* end::Col */}
        {/* begin::Col */}

        <div className='col-xxl-6'>
          <IncomeAndExpenseWidget
            className='card-xxl-stretch-50 mb-5'
            title={intl.formatMessage({id: 'DASHBOARD.INCOME'})}
            bgPath={`url('${toAbsoluteUrl('/media/basel/svg/gelir-bg.svg')}')`}
            today={`${accountStatements?.gelir?.income_today}`}
            week={`${accountStatements?.gelir?.income_weekly}`}
            month={`${accountStatements?.gelir?.income_monthly}`}
            total={`${accountStatements?.gelir?.income_all}`}
          />
          <IncomeAndExpenseWidget
            className='card-xxl-stretch-50 mb-5'
            title={intl.formatMessage({id: 'DASHBOARD.EXPENSE'})}
            bgPath={`url('${toAbsoluteUrl('/media/basel/svg/gider-bg.svg')}')`}
            today={`${accountStatements?.gider?.expense_today}`}
            week={`${accountStatements?.gider?.expense_weekly}`}
            month={`${accountStatements?.gider?.expense_monthly}`}
            total={`${accountStatements?.gider?.expense_all}`}
          />
        </div>
        {/* end::Col */}
      </div>

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xxl-12'>
          <StoppingRequests className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>

        {/* end::Col */}
      </div>
      {/* end::Row */}

       {/* begin::Row */}
       <div className='row gy-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xxl-12'>
          <InviteFacility className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>

        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xxl-6'>
          <NumberOfLectureByDate
            className='card-xxl-stretch mb-5 mb-xl-8'
            svgIcon='element-11'
            color='danger'
            description='Weekly Income'
            change='750$'
            numberOfLectureByDateData={numberOfLectureByDateData}
          />
        </div>
        <div className='col-xxl-6'>
          <RoomUsageRate
            className='card-xxl-stretch mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='170px'
            roomUsageRate={roomUsageRate}
          />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
    </>
  )
}

export default AdminDashboard
