//React
import {FC} from 'react'

//CLSX - Dynamic CSS Class Creator
import clsx from 'clsx'

//Models
import {User} from '../../../models'

type Props = {
  user: User | undefined
}

const AvatarCell: FC<Props> = ({user}) => {
  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <a href='#'>
          {user?.avatar ? (
            <div className='symbol-label'>
              <img
                src={user?.avatar}
                alt={`${user?.first_name}_${user?.last_name}`}
                className='w-100'
              />
            </div>
          ) : (
            <div
              className={clsx(
                'symbol-label fs-3',
                `bg-light-${user?.status ? 'success' : 'danger'}`,
                `text-${user?.status ? 'success' : 'danger'}`
              )}
            >
              {`${user?.first_name?.charAt(0).toUpperCase()}${user?.last_name
                ?.charAt(0)
                .toUpperCase()}`}
            </div>
          )}
        </a>
      </div>
    </div>
  )
}

export default AvatarCell
