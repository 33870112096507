import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {BASE_URL} from './paths'
import {getConfig} from '../i18n/Metronici18n'
import {RootState} from '../redux'
import {TAGS} from './helpers/tags'

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, {getState}) => {
    const lang = getConfig()
    headers.set('Accept-Language', `${lang.selectedLang}`)
    const token = (getState() as RootState).authReducer.token
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }

    return headers
  },
})

// API yapılandırmasını oluştur
const api = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes: TAGS,
  endpoints: (builder) => ({}),
})

export default api
