// createApi
import api from '..'

// paths
import {INVITE_FACILITY} from '../paths'

// helpers - serializeQueryArgs
import serializeQueryArgs from '../helpers/serializeQueryArgs'
import filterQueryUrlCreater from '../helpers/filterQueryUrlCreater'

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    inviteFacilitys: builder.query({
      query: (credentials) => ({
        url: `${INVITE_FACILITY}`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        const result = response?.data?.data

        return result
          ? [...result.map(({id}) => ({type: 'INVITE_FACILITY', id})), {type: 'INVITE_FACILITY', id: 'LIST'}]
          : [{type: 'INVITE_FACILITY', id: 'LIST'}]
      },
    }),
    updateInviteFacility: builder.mutation({
      query: (credentials) => ({
        url: `${INVITE_FACILITY}${credentials.endpointData.id}/`,
        method: 'PUT',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [{type: 'INVITE_FACILITY', id: arg.endpointData.id}]
      },
    }),
   
  }),
})

export const {
  useInviteFacilitysQuery,
  useUpdateInviteFacilityMutation,
} = extendedApi
