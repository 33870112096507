//React
import React, {FC, useEffect, useState} from 'react'

//CLSX - Dynamic CSS Class Creator
import clsx from 'clsx'

//React Router Dom
import {useNavigate, Link, useSearchParams} from 'react-router-dom'

// React Hook Form - Yup Validation
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'

//Helpers- Create Public File Path
import {toAbsoluteUrl} from '../../helpers/AssetHelpers'

//Redux ToolKit API
import {useResetpasswordMutation} from '../../api/endpoints/auth'

//Redux - Dispatch - Actions - State
import {useDispatch, useSelector} from 'react-redux'
import {selectInitialData} from '../../redux/slice/initialDataSlice'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

const ResetPassword: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const initialdata = useSelector(selectInitialData)
  const [request, {isLoading, isError, data}] = useResetpasswordMutation()
  const [searchParams] = useSearchParams()

  const id = searchParams.get('id')
  const resetToken = searchParams.get('reset-token')

  const schema = Yup.object().shape({
    new_password1: Yup.string()
      .min(8, intl.formatMessage({id: 'FORM.VALIDATION.MIN_LENGTH_FIELD'}, {name: '8'}))
      .max(50, intl.formatMessage({id: 'FORM.VALIDATION.MAX_LENGTH_FIELD'}, {name: '50'}))
      .required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    new_password2: Yup.string()
      .min(8, intl.formatMessage({id: 'FORM.VALIDATION.MIN_LENGTH_FIELD'}, {name: '8'}))
      .max(50, intl.formatMessage({id: 'FORM.VALIDATION.MAX_LENGTH_FIELD'}, {name: '50'}))
      .oneOf(
        [Yup.ref('new_password1')],
        intl.formatMessage({id: 'FORM.VALIDATION.PASSWORD_CONFIRM'})
      )
      .required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
  })

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: {errors, touchedFields, isSubmitting, isValid},
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      new_password1: '',
      new_password2: '',
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = (formData: any) => {
    if (id != null && resetToken != null) {
      const sendFormdata = new FormData()

      Object.keys(formData).forEach(function (key) {
        sendFormdata.append(key, formData[key])
      })

      sendFormdata.append('id', id)
      sendFormdata.append('reset-token', resetToken)

      request({
        endpointData: sendFormdata,
        toastSuccessMessageStatus: true,
        toastErrorMessageStatus: true,
      })
    }
  }

  useEffect(() => {
    if (!isLoading && !isError && data !== undefined) {
      navigate('/auth/singin')
    }
  }, [isLoading, isError, data])

  return (
    <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate>
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>
          {intl.formatMessage({id: 'RESET_PASSWORD_PAGE.TITLE'})}
        </h1>
        <div className='text-gray-500 fw-semibold fs-6'>
          {intl.formatMessage({id: 'RESET_PASSWORD_PAGE.SUB_TITLE'})}
        </div>
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6 '>
          {intl.formatMessage({id: 'FORM.LABEL.NEW_PASSWORD'})}
        </label>
        <input
          {...register('new_password1')}
          type='password'
          placeholder={intl.formatMessage({id: 'FORM.PLACEHOLDER.NEW_PASSWORD'})}
          autoComplete='off'
          className={clsx('form-control bg-transparent')}
        />
        {touchedFields?.new_password1 && errors?.new_password1 && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{errors?.new_password1?.message}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6 '>
          {intl.formatMessage({id: 'FORM.LABEL.NEW_PASSWORD_CONFIRM'})}
        </label>
        <input
          {...register('new_password2')}
          type='password'
          placeholder={intl.formatMessage({id: 'FORM.PLACEHOLDER.NEW_PASSWORD_CONFIRM'})}
          autoComplete='off'
          className={clsx('form-control bg-transparent')}
        />
        {touchedFields?.new_password2 && errors?.new_password2 && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{errors?.new_password2?.message}</span>
            </div>
          </div>
        )}
      </div>

      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={isSubmitting || !isValid}
        >
          {!isLoading && (
            <span className='indicator-label'>
              {intl.formatMessage({id: 'FORM.BUTTON.SUBMIT'})}
            </span>
          )}
          {isLoading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'MESSAGE.PLEASE_WAITING'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        <Link to='/auth/singin' className='link-primary'>
          {intl.formatMessage({id: 'SINGUP_PAGE.SINGUP'})}
        </Link>
      </div>
    </form>
  )
}

export default ResetPassword
