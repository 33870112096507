//React
import React, {useEffect, useRef, useState} from 'react'

import clsx from 'clsx'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

//React-Router-Dom
import {useLocation, useParams, useNavigate} from 'react-router-dom'

//getLocaleConfig
import {getConfig, dateFormat} from '../../../../i18n/Metronici18n'

//Data Fsn
import {addDays, parseISO, differenceInHours} from 'date-fns'

//Redux ToolKit API
import {useCalendarCustormerQuery} from '../../../../api/endpoints/customer'
import {useLecturesQuery} from '../../../../api/endpoints/lecture'
import {useROOMsQuery} from '../../../../api/endpoints/room'
import {useStaffsQuery} from '../../../../api/endpoints/staff'

//Helpers
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'

type Props = {
  className: string
  type: 'general' | 'overview'
  currentUserId: number | null | undefined
}

const TimeLine: React.FC<Props> = ({className, type, currentUserId}) => {
  const params = useParams()
  const userId = type == 'general' ? params?.userId : currentUserId
  const intl = useIntl()
  const navigate = useNavigate()

  const [selectedDate, setSelectedDate] = useState(new Date())

  const {data, isLoading, isFetching, isError} = useCalendarCustormerQuery({
    endpointData: {
      id: userId,
      limit: -1,
      search: '',
      room: '',
      personel: '',
      appointmentstatus: '',
      lecture: '',
      start_date: dateFormat(addDays(new Date(), -3), 'yyyy-MM-dd'),
      end_date: dateFormat(addDays(new Date(), 3), 'yyyy-MM-dd'),
    },
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })

  const {data: lectureData} = useLecturesQuery({
    endpointData: {
      limit: -1,
    },
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })
  const {data: roomData} = useROOMsQuery({
    endpointData: {
      limit: -1,
    },
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })

  const {data: staffData} = useStaffsQuery({
    endpointData: {
      limit: -1,
    },
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })

  return (
    <>
      {/*begin::Timeline widget 3*/}
      <div className={`card ${className}`}>
        {/*begin::Header*/}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold text-gray-900'>
              {intl.formatMessage({id: 'DASHBOARD.SCHEDULE_LECTURES.TITLE'})}
            </span>

            {/* <span className='text-muted mt-1 fw-semibold fs-7'>Total 424,567 deliveries</span> */}
          </h3>

          {/*begin::Toolbar*/}
          {/* <div className='card-toolbar'>
            <a href='#' className='btn btn-sm btn-light'>
              Report Cecnter
            </a>
          </div> */}
          {/*end::Toolbar*/}
        </div>
        {/*end::Header*/}

        {/*begin::Body*/}
        <div className='card-body pt-7 px-0'>
          <div className='d-flex justify-content-center aligin-items-center mb-2'>
            <KTIcon iconName='information' className='fs-1 text-primary me-1' />
            <span className='fs-6 text-primary me-2'>
              {intl.formatMessage({id: 'FORM.OPTION.ATTENDED_THE_LECTURE'})}
            </span>
            <KTIcon iconName='information' className='fs-1 text-warning me-1' />
            <span className='fs-6 text-warning me-2'>
              {intl.formatMessage({id: 'FORM.OPTION.APPOINTMENT'})}
            </span>
            <KTIcon iconName='information' className='fs-1 text-danger me-1' />
            <span className='fs-6 text-danger me-2'>
              {intl.formatMessage({id: 'FORM.OPTION.CAN_JOIN'})}
            </span>
            <KTIcon iconName='information' className='fs-1 text-info me-1' />
            <span className='fs-6 text-info'>
              {intl.formatMessage({id: 'FORM.OPTION.NOT_ATTENDED_THE_LECTURE'})}
            </span>
          </div>
          {/*begin::Nav*/}
          <ul
            className='nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex justify-content-between mb-8 px-5'
            role='tablist'
          >
            {/*begin::Nav item*/}
            <li className='nav-item p-0 ms-0' role='presentation'>
              {/*begin::Date*/}
              <button
                className={clsx(
                  'nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger',
                  {
                    active:
                      dateFormat(selectedDate, 'yyyy-MM-dd') ===
                      dateFormat(addDays(new Date(), -3), 'yyyy-MM-dd'),
                  }
                )}
                onClick={() => {
                  setSelectedDate(addDays(new Date(), -3))
                }}
                tabIndex={-1}
              >
                <span className='fs-7 fw-semibold'>
                  {dateFormat(addDays(new Date(), -3), 'EEE')}
                </span>
                <span
                  className={
                    data &&
                    data?.data?.data &&
                    data?.data?.data.filter(
                      (x: any) => x.s_date == dateFormat(addDays(new Date(), -3), 'yyyy-MM-dd')
                    ).length > 0
                      ? `fs-6 fw-bold text-primary`
                      : `fs-6 fw-bold text-secondary`
                  }
                >
                  {dateFormat(addDays(new Date(), -3), 'dd')}
                </span>
              </button>
              {/*end::Date*/}
            </li>
            {/*end::Nav item*/}
            {/*begin::Nav item*/}
            <li className='nav-item p-0 ms-0' role='presentation'>
              {/*begin::Date*/}
              <button
                className={clsx(
                  'nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger',
                  {
                    active:
                      dateFormat(selectedDate, 'yyyy-MM-dd') ===
                      dateFormat(addDays(new Date(), -2), 'yyyy-MM-dd'),
                  }
                )}
                onClick={() => {
                  setSelectedDate(addDays(new Date(), -2))
                }}
                tabIndex={-1}
              >
                <span className='fs-7 fw-semibold'>
                  {dateFormat(addDays(new Date(), -2), 'EEE')}
                </span>
                <span
                  className={
                    data &&
                    data?.data?.data &&
                    data?.data?.data.filter(
                      (x: any) => x.s_date == dateFormat(addDays(new Date(), -2), 'yyyy-MM-dd')
                    ).length > 0
                      ? `fs-6 fw-bold text-primary`
                      : `fs-6 fw-bold text-secondary`
                  }
                >
                  {dateFormat(addDays(new Date(), -2), 'dd')}
                </span>
              </button>
              {/*end::Date*/}
            </li>
            {/*end::Nav item*/}
            {/*begin::Nav item*/}
            <li className='nav-item p-0 ms-0' role='presentation'>
              {/*begin::Date*/}
              <button
                className={clsx(
                  'nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger',
                  {
                    active:
                      dateFormat(selectedDate, 'yyyy-MM-dd') ===
                      dateFormat(addDays(new Date(), -1), 'yyyy-MM-dd'),
                  }
                )}
                onClick={() => {
                  setSelectedDate(addDays(new Date(), -1))
                }}
                tabIndex={-1}
              >
                <span className='fs-7 fw-semibold'>
                  {dateFormat(addDays(new Date(), -1), 'EEE')}
                </span>
                <span
                  className={
                    data &&
                    data?.data?.data &&
                    data?.data?.data.filter(
                      (x: any) => x.s_date == dateFormat(addDays(new Date(), -1), 'yyyy-MM-dd')
                    ).length > 0
                      ? `fs-6 fw-bold text-primary`
                      : `fs-6 fw-bold text-secondary`
                  }
                >
                  {dateFormat(addDays(new Date(), -1), 'dd')}
                </span>
              </button>
              {/*end::Date*/}
            </li>
            {/*end::Nav item*/}
            {/*begin::Nav item*/}
            <li className='nav-item p-0 ms-0' role='presentation'>
              {/*begin::Date*/}
              <button
                className={clsx(
                  'nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger',
                  {
                    active:
                      dateFormat(selectedDate, 'yyyy-MM-dd') ===
                      dateFormat(addDays(new Date(), 0), 'yyyy-MM-dd'),
                  }
                )}
                onClick={() => {
                  setSelectedDate(addDays(new Date(), 0))
                }}
                tabIndex={-1}
              >
                <span className='fs-7 fw-semibold'>
                  {dateFormat(addDays(new Date(), 0), 'EEE')}
                </span>
                <span
                  className={
                    data &&
                    data?.data?.data &&
                    data?.data?.data.filter(
                      (x: any) => x.s_date == dateFormat(addDays(new Date(), 0), 'yyyy-MM-dd')
                    ).length > 0
                      ? `fs-6 fw-bold text-primary`
                      : `fs-6 fw-bold text-secondary`
                  }
                >
                  {dateFormat(addDays(new Date(), 0), 'dd')}
                </span>
              </button>
              {/*end::Date*/}
            </li>
            {/*end::Nav item*/}
            {/*begin::Nav item*/}
            <li className='nav-item p-0 ms-0' role='presentation'>
              {/*begin::Date*/}
              <button
                className={clsx(
                  'nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger',
                  {
                    active:
                      dateFormat(selectedDate, 'yyyy-MM-dd') ===
                      dateFormat(addDays(new Date(), 1), 'yyyy-MM-dd'),
                  }
                )}
                onClick={() => {
                  setSelectedDate(addDays(new Date(), 1))
                }}
                tabIndex={-1}
              >
                <span className='fs-7 fw-semibold'>
                  {dateFormat(addDays(new Date(), 1), 'EEE')}
                </span>
                <span
                  className={
                    data &&
                    data?.data?.data &&
                    data?.data?.data.filter(
                      (x: any) => x.s_date == dateFormat(addDays(new Date(), 1), 'yyyy-MM-dd')
                    ).length > 0
                      ? `fs-6 fw-bold text-primary`
                      : `fs-6 fw-bold text-secondary`
                  }
                >
                  {dateFormat(addDays(new Date(), 1), 'dd')}
                </span>
              </button>
              {/*end::Date*/}
            </li>
            {/*end::Nav item*/}
            {/*begin::Nav item*/}
            <li className='nav-item p-0 ms-0' role='presentation'>
              {/*begin::Date*/}
              <button
                className={clsx(
                  'nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger',
                  {
                    active:
                      dateFormat(selectedDate, 'yyyy-MM-dd') ===
                      dateFormat(addDays(new Date(), 2), 'yyyy-MM-dd'),
                  }
                )}
                onClick={() => {
                  setSelectedDate(addDays(new Date(), 2))
                }}
                tabIndex={-1}
              >
                <span className='fs-7 fw-semibold'>
                  {dateFormat(addDays(new Date(), 2), 'EEE')}
                </span>
                <span
                  className={
                    data &&
                    data?.data?.data &&
                    data?.data?.data.filter(
                      (x: any) => x.s_date == dateFormat(addDays(new Date(), 2), 'yyyy-MM-dd')
                    ).length > 0
                      ? `fs-6 fw-bold text-primary`
                      : `fs-6 fw-bold text-secondary`
                  }
                >
                  {dateFormat(addDays(new Date(), 2), 'dd')}
                </span>
              </button>
              {/*end::Date*/}
            </li>
            {/*end::Nav item*/}
            {/*begin::Nav item*/}
            <li className='nav-item p-0 ms-0' role='presentation'>
              {/*begin::Date*/}
              <button
                className={clsx(
                  'nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger',
                  {
                    active:
                      dateFormat(selectedDate, 'yyyy-MM-dd') ===
                      dateFormat(addDays(new Date(), 3), 'yyyy-MM-dd'),
                  }
                )}
                onClick={() => {
                  setSelectedDate(addDays(new Date(), 3))
                }}
                tabIndex={-1}
              >
                <span className='fs-7 fw-semibold'>
                  {dateFormat(addDays(new Date(), 3), 'EEE')}
                </span>
                <span
                  className={
                    data &&
                    data?.data?.data &&
                    data?.data?.data.filter(
                      (x: any) => x.s_date == dateFormat(addDays(new Date(), 3), 'yyyy-MM-dd')
                    ).length > 0
                      ? `fs-6 fw-bold text-primary`
                      : `fs-6 fw-bold text-secondary`
                  }
                >
                  {dateFormat(addDays(new Date(), 3), 'dd')}
                </span>
              </button>
              {/*end::Date*/}
            </li>
            {/*end::Nav item*/}
          </ul>
          {/*end::Nav*/}

          {/*begin::Tab Content (ishlamayabdi)*/}
          <div className='tab-content mb-2 px-9'>
            {/*begin::Tap pane*/}
            {data &&
              data?.data?.data &&
              data?.data?.data
                .filter((x: any) => x.s_date == dateFormat(selectedDate, 'yyyy-MM-dd'))
                .map((event: any, index: number) => {
                  console.log(event)
                  let className = ''

                  const startDateTimeString = `${event.s_date}T${event.start_time}`
                  const endDateTimeString = `${event.s_date}T${event.end_time}`

                  const startDate = parseISO(startDateTimeString)
                  const endDate = parseISO(endDateTimeString)

                  const diff = differenceInHours(new Date(endDate), new Date())
                  const status = event.status

                  if (diff > 0) {
                    if (status == 0) {
                      className = 'bg-primary'
                    }
                    if (status == 1) {
                      className = 'bg-warning'
                    }
                    if (status == 2) {
                      className = 'bg-danger'
                    }
                  } else {
                    if (status == 0) {
                      className = 'bg-primary'
                    }
                    if (status == 1) {
                      className = 'bg-info'
                    }
                    if (status == 2) {
                      className = 'bg-danger'
                    }
                  }

                  return (
                    <div
                      key={index}
                      className='d-flex align-items-center mb-6'
                      style={{cursor: 'pointer'}}
                      onDoubleClick={() => {
                        navigate(`/customer-management/customer/${userId}/calendar/${event.date}`)
                      }}
                    >
                      {/*begin::Bullet*/}
                      <span
                        data-kt-element='bullet'
                        className={`bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 ${className}`}
                      ></span>
                      {/*end::Bullet*/}

                      {/*begin::Info*/}
                      <div className='flex-grow-1 me-5'>
                        {/*begin::Time*/}
                        <div className='text-gray-800 fw-semibold fs-2'>
                          {`${event?.start_time
                            .split(':')
                            .slice(0, 2)
                            .join(':')} - ${event?.end_time.split(':').slice(0, 2).join(':')}`}
                          {/* <span className='text-gray-500 fw-semibold fs-7'>AM </span> */}
                        </div>
                        {/*end::Time*/}

                        {/*begin::Description*/}
                        <div
                          className='fw-semibold fs-6'
                          style={{
                            color:
                              lectureData?.data?.data == undefined
                                ? ''
                                : lectureData?.data?.data.filter(
                                    (l: any) => l.id == event.lecture
                                  )[0]?.color,
                          }}
                        >
                          {lectureData?.data?.data == undefined
                            ? ''
                            : lectureData?.data?.data.filter((l: any) => l.id == event.lecture)[0]
                                ?.name}
                        </div>
                        {/*end::Description*/}

                        {/*begin::Link*/}

                        <div className='d-flex'>
                          <div className='d-flex justify-content-center align-items-center me-2'>
                            <img src={`${toAbsoluteUrl('/media/basel/svg/item-user-tick.svg')}`} />
                            <span className='fw-bold fs-5 text-gray-500 ms-1'>{`${
                              staffData?.data?.data == undefined
                                ? ''
                                : staffData?.data?.data.filter(
                                    (x: any) => x.id == event?.personel
                                  )[0]?.first_name
                            } ${
                              staffData?.data?.data == undefined
                                ? ''
                                : staffData?.data?.data.filter(
                                    (x: any) => x.id == event?.personel
                                  )[0]?.last_name
                            }`}</span>
                          </div>
                          <div className='d-flex justify-content-center align-items-center me-2'>
                            <img src={`${toAbsoluteUrl('/media/basel/svg/item-location.svg')}`} />
                            <span className='fw-bold fs-5 text-gray-500 ms-1'>{`${
                              roomData?.data?.data == undefined
                                ? ''
                                : roomData?.data?.data.filter((x: any) => x.id == event?.room)[0]
                                    ?.name
                            }`}</span>
                          </div>
                        </div>
                        {/*end::Link*/}
                      </div>
                      {/*end::Info*/}

                      {/*begin::Action*/}
                      {/* <a
                      href='#'
                      className='btn btn-sm btn-light'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_create_project'
                    >
                      View
                    </a> */}
                      {/*end::Action*/}
                    </div>
                  )
                })}

            {/*end::Tap pane*/}
          </div>
          {/*end::Tab Content*/}
        </div>
        {/*end: Card Body*/}
      </div>
    </>
  )
}

export {TimeLine}
