/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useEffect, Fragment} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {ChatInner} from './ChatInner'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

//Redux - Dispatch - Actions - State
import {useDispatch, useSelector} from 'react-redux'
import {useStaffsQuery, useStudentStaffQuery} from '../../api/endpoints/staff'
import {useCustormersQuery} from '../../api/endpoints/customer'
import {useMessagesQuery} from '../../api/endpoints/summary'
import {selectCurrentUser} from '../../redux/slice/authSlice'

//AvatarCell
import AvatarCell from '../table/commonCells/AvatarCell'

//Model
import {User} from '../../models'

interface UserWithUnReadMessages extends User {
  unReadMessages: number
}

type Props = {
  selectedUser: UserWithUnReadMessages | null
  userType: string
}

const DrawerMessenger: FC = () => {
  const intl = useIntl()
  const currentUser = useSelector(selectCurrentUser)
  const [searchValue, setSearchValue] = useState('')
  const [staff, setStaff] = useState<UserWithUnReadMessages[]>([])
  const [customer, setCustomer] = useState<UserWithUnReadMessages[]>([])
  const [selectedUserWithType, setSelectedUserWithType] = useState<Props>({
    selectedUser: null,
    userType: '',
  })

  const is_customer = currentUser?.is_customer

  const {
    data: dataStaff,
    isLoading: isLoadingStaff,
    isFetching: isFetchingStaff,
  } = useStaffsQuery({
    endpointData: {
      limit: -1,
    },
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })

  const {
    data: dataCustomer,
    isLoading: isLoadingCustomer,
    isFetching: isFetchingCustomer,
  } = useCustormersQuery({
    endpointData: {
      limit: -1,
    },
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })

  const {
    data: dataStudentStaff,
    isLoading: isLoadingStudentStaff,
    isFetching: isFetchingStudentStaff,
  } = useStudentStaffQuery({
    endpointData: {
      id: currentUser?.id,
      limit: -1,
    },
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: false,
  })

  const {
    data: dataMessages,
    isLoading: isLoadingMessages,
    isFetching: isFetchingMessages,
  } = useMessagesQuery({
    endpointData: {},
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })

  useEffect(() => {
    if (
      dataStaff?.data?.data != undefined &&
      !isLoadingStaff &&
      !isFetchingStaff &&
      dataMessages?.data != undefined &&
      !isLoadingMessages &&
      !isFetchingMessages
    ) {
      let st: UserWithUnReadMessages[] = []

      dataStaff?.data?.data.forEach((value) => {
        let unReadMessages = 0

        Object.keys(dataMessages?.data?.staff).forEach(function (key) {
          if (key == value?.id) {
            unReadMessages = dataMessages?.data?.staff[key]
          }
        })

        if (currentUser?.id != value.id) {
          st.push({
            ...value,
            unReadMessages,
          })
        }
      })

      const stf = st
        .filter((x) =>
          searchValue == undefined || searchValue == null || searchValue == ''
            ? x
            : x?.first_name.toLowerCase().startsWith(searchValue.toLowerCase()) ||
              x.last_name.toLowerCase().startsWith(searchValue.toLowerCase())
        )
        .sort((a, b) => b.unReadMessages - a.unReadMessages)

      setStaff([...stf])
    }
  }, [
    dataStaff,
    isLoadingStaff,
    isFetchingStaff,
    dataMessages,
    isLoadingMessages,
    isFetchingMessages,
    searchValue,
  ])

  useEffect(() => {
    if (
      dataCustomer?.data?.data != undefined &&
      !isLoadingCustomer &&
      !isFetchingCustomer &&
      dataStudentStaff?.data?.data != undefined &&
      !isLoadingStudentStaff &&
      !isFetchingStudentStaff &&
      dataMessages?.data != undefined &&
      !isLoadingMessages &&
      !isFetchingMessages &&
      !is_customer
    ) {
      let st: UserWithUnReadMessages[] = []

      dataStudentStaff?.data?.data.forEach((value) => {
        let unReadMessages = 0

        Object.keys(dataMessages?.data?.customer).forEach(function (key) {
          if (key == value?.customer_id) {
            unReadMessages = dataMessages?.data?.customer[key]
          }
        })
        if (currentUser?.id != value?.customer_id) {
          st.push({
            ...dataCustomer?.data?.data.find((x) => x.id == value?.customer_id),
            unReadMessages,
          })
        }
      })
      const stf = st
        .filter((x) =>
          searchValue == undefined || searchValue == null || searchValue == ''
            ? x
            : x?.first_name.toLowerCase().startsWith(searchValue.toLowerCase()) ||
              x.last_name.toLowerCase().startsWith(searchValue.toLowerCase())
        )
        .sort((a, b) => b.unReadMessages - a.unReadMessages)

      setCustomer([...stf])
    }
  }, [
    dataCustomer,
    isLoadingCustomer,
    isFetchingCustomer,
    dataStudentStaff,
    isLoadingStudentStaff,
    isFetchingStudentStaff,
    dataMessages,
    isLoadingMessages,
    isFetchingMessages,
    searchValue,
    is_customer,
  ])

  const onChangeText = (event) => {
    setSearchValue(event?.target.value)
  }

  return (
    <div
      id='kt_drawer_chat'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='chat'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'80vw', 'md': '80vw'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_drawer_chat_toggle'
      data-kt-drawer-close='#kt_drawer_chat_close'
    >
      <div
        className='row w-100'
        style={{
          height: '100vh',
        }}
      >
        <div className='col-4 h-100'>
          <div className='card d-flex h-100 card-flush d-flex'>
            <div className='card-header pt-7'>
              <form className='w-100 position-relative' autoComplete='off'>
                <KTIcon
                  iconName='magnifier'
                  className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 ms-5 translate-middle-y'
                />

                <input
                  type='text'
                  className='form-control form-control-solid px-15'
                  name='search'
                  placeholder={intl.formatMessage({id: 'FORM.PLACEHOLDER.SEARCH'})}
                  onChange={onChangeText}
                />
              </form>
            </div>

            <div className='card-body flex-grow-1 overflow-auto  mt-2 mb-2 pt-5'>
              <div className=' me-n5 flex-grow-1 overflow-auto  pe-5 h-100'>
                {staff.length < 1 ? null : (
                  <>
                    <p className='h4'>{intl.formatMessage({id: 'FORM.LABEL.INSTRUCTORS_STAFF'})}</p>
                    {staff.map((value) => (
                      <Fragment key={value?.id}>
                        <div
                          className='d-flex flex-stack py-4'
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setSelectedUserWithType({
                              selectedUser: value,
                              userType: 'staff',
                            })
                          }}
                        >
                          <div className='d-flex align-items-center'>
                            <AvatarCell user={value} />

                            <div className='ms-5'>
                              <span className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                                {`${value?.first_name} ${value?.last_name}`}
                              </span>
                              <div className='fw-bold text-gray-400'>{value?.username}</div>
                            </div>
                          </div>

                          <div className='d-flex flex-column align-items-end ms-2'>
                            {value?.unReadMessages > 0 ? (
                              <span className='badge badge-sm badge-circle badge-light-success'>
                                {value?.unReadMessages}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className='separator separator-dashed d-none'></div>
                      </Fragment>
                    ))}
                  </>
                )}

                {is_customer == true || customer.length < 1 ? null : (
                  <>
                    <p className='h4 mt-4'>{intl.formatMessage({id: 'FORM.LABEL.CUSTOMERS'})}</p>
                    {customer.map((value) => (
                      <Fragment key={value?.id}>
                        <div
                          className='d-flex flex-stack py-4'
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setSelectedUserWithType({
                              selectedUser: value,
                              userType: 'customer',
                            })
                          }}
                        >
                          <div className='d-flex align-items-center'>
                            <AvatarCell user={value} />

                            <div className='ms-5'>
                              <span className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                                {`${value?.first_name} ${value?.last_name}`}
                              </span>
                              <div className='fw-bold text-gray-400'>{value?.username}</div>
                            </div>
                          </div>

                          <div className='d-flex flex-column align-items-end ms-2'>
                            {value?.unReadMessages > 0 ? (
                              <span className='badge badge-sm badge-circle badge-light-success'>
                                {value?.unReadMessages}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className='separator separator-dashed d-none'></div>
                      </Fragment>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='col-8 h-100'>
          {selectedUserWithType?.selectedUser === null ? null : (
            <ChatInner
              isDrawer={false}
              selectedUser={selectedUserWithType?.selectedUser}
              userType={selectedUserWithType?.userType}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default DrawerMessenger
