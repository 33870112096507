///React
import React, {useEffect, useRef, useState} from 'react'

//Helpers
import {KTIcon} from '../../../../_metronic/helpers'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

//Redux - Dispatch - Actions - State
import {useDispatch, useSelector} from 'react-redux'
import {selectInitialData} from '../../../redux/slice/initialDataSlice'
import {
  useStoppingRequestsQuery,
  useUpdateStoppingRequestMutation,
} from '../../../api/endpoints/stoppingRequest'

//Modal
import WarningModal from '../../../components/modalForms/warningModal'
import {dateFormat} from '../../../i18n/Metronici18n'

type Props = {
  className: string
}

const StoppingRequests: React.FC<Props> = ({className}) => {
  const intl = useIntl()

  const initialdata = useSelector(selectInitialData)

  const [requestMutation1] = useUpdateStoppingRequestMutation()

  const [warningModalState, setWarningModalState] = useState({
    modalVisible: false,
    status: -1,
    id: -1,
  })

  const [data, setData] = useState<any[]>([])
  const [filter, setFilter] = useState(0)

  const {
    data: dataQuery1,
    isLoading: isLoadingQuery1,
    isFetching: isFetchingQuery1,
    isError: isErrorQuery1,
    refetch: refetchQuery1,
  } = useStoppingRequestsQuery({
    endpointData: {},
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })

  useEffect(() => {
    if (
      dataQuery1?.data?.data != undefined &&
      isLoadingQuery1 == false &&
      isFetchingQuery1 == false &&
      initialdata?.stopping_request_status != undefined
    ) {
      setData([
        ...dataQuery1?.data?.data.filter((x) => (filter === -1 ? true : x.status == filter)),
      ])
    }
  }, [dataQuery1, isFetchingQuery1, isLoadingQuery1, initialdata, filter])

  const update = async (id, status, cause_of_reject) => {
    const formData = new FormData()
    formData.append('status', status)
    formData.append('cause_of_reject', cause_of_reject)
    requestMutation1({
      endpointData: {
        id,
        formData,
      },
      toastSuccessMessageStatus: false,
      toastErrorMessageStatus: true,
    })
  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {intl.formatMessage({id: 'FORM.LABEL.STOPPING_REQUESTS'})}
          </span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>

          <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'>
                {intl.formatMessage({id: 'FILTER.OPTIONS'})}
              </div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5'>
              <div className='mb-10'>
                <label className='form-label fw-bold'>
                  {intl.formatMessage({id: 'FORM.LABEL.STATUS'})}
                </label>

                <div>
                  <select
                    className='form-select form-select-solid'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    value={filter}
                    onChange={(event) => {
                      setFilter(parseInt(event.target.value))
                    }}
                  >
                    <option value={-1}>{intl.formatMessage({id: 'FORM.OPTION.ALL'})}</option>
                    {initialdata?.stopping_request_status === undefined
                      ? null
                      : initialdata?.stopping_request_status.map((val, ind) => (
                          <option key={ind} value={val?.id}>
                            {val?.name}
                          </option>
                        ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>
                  {intl.formatMessage({id: 'FORM.LABEL.CUSTOMER_AUTHORISATION'})}
                </th>
                <th className='min-w-140px'>
                  {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT.PACKAGE_SALES.STEP1'})}
                </th>
                <th className='min-w-120px'>
                  {intl.formatMessage({id: 'FORM.LABEL.STOPPING_DAY_AND_START_DATE'})}
                </th>
                <th className='min-w-120px'>
                  {intl.formatMessage({id: 'FORM.LABEL.REASON_FOR_REQUEST'})}
                </th>

                <th className='min-w-120px'>{intl.formatMessage({id: 'FORM.LABEL.STATUS'})}</th>
                <th className='min-w-120px'>
                  {intl.formatMessage({id: 'FORM.LABEL.RESULT_STATEMENT'})}
                </th>
                <th className='min-w-100px text-end'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {data.map((value, index) => (
                <tr key={index}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px me-5'>
                        <img src={value?.avatar} alt='' />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                          {`${value?.first_name} ${value?.last_name}`}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {value?.username}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                      {`${value?.package} / ${value?.lecture}`}
                    </a>
                  </td>
                  <td>{`${value?.requested_day} / ${dateFormat(
                    new Date(value?.start_date),
                    'dd MMM yyyy'
                  )}`}</td>
                  <td>{`${dateFormat(new Date(value?.created_at), 'dd.MM.yyyy')} - ${
                    value?.cause_of_discontinuance
                  }`}</td>
                  <td>
                    {
                      initialdata?.stopping_request_status.filter(
                        (val) => val?.id == value?.status
                      )[0]?.name
                    }
                  </td>
                  <td>{`${dateFormat(new Date(value?.transaction_date), 'dd.MM.yyyy')} - ${
                    value?.cause_of_reject == null ? '' : value?.cause_of_reject
                  }`}</td>

                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <button
                        className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
                        onClick={(event) => {
                          event.stopPropagation()
                          setWarningModalState({
                            modalVisible: true,
                            id: value.id,
                            status: 1,
                          })
                        }}
                      >
                        <KTIcon iconName='shield-tick' className='fs-3' />
                      </button>
                      <button
                        className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                        onClick={(event) => {
                          event.stopPropagation()
                          setWarningModalState({
                            modalVisible: true,
                            id: value.id,
                            status: 2,
                          })
                        }}
                      >
                        <KTIcon iconName='shield-cross' className='fs-3' />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {warningModalState.modalVisible ? (
        <WarningModal
          modalVisible={warningModalState.modalVisible}
          desc={true}
          onCloseModal={() =>
            setWarningModalState({
              modalVisible: false,
              status: -1,
              id: -1,
            })
          }
          onContinue={(text) => {
            update(warningModalState.id, warningModalState.status, text)
            setWarningModalState({
              modalVisible: false,
              status: -1,
              id: -1,
            })
          }}
        />
      ) : null}
    </div>
  )
}

export {StoppingRequests}
