import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, QuickLinks} from '../../../partials'
import clsx from 'clsx'
import {Link, useLocation} from 'react-router-dom'

//Redux - Dispatch - Actions - State
import {useDispatch, useSelector} from 'react-redux'
import {setAuthState, selectCurrentUser, selectToken} from '../../../../app/redux/slice/authSlice'

const AsideFooter = () => {
  const location = useLocation()
  const currentUser = useSelector(selectCurrentUser)
  const is_customer = currentUser?.is_customer
  return (
    <div
      className='aside-footer d-flex flex-column align-items-center flex-column-auto'
      id='kt_aside_footer'
    >
      {is_customer == true ? null : (
        <>
          <Link
            className={clsx(
              'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light mb-4',
              {active: location.pathname.includes('/facility-room-and-gates')}
            )}
            to={'/facility-room-and-gates'}
          >
            <KTIcon iconName={'courier-express'} className='fs-2x' />
          </Link>
          <Link
            className={clsx(
              'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light mb-4',
              {active: location.pathname.includes('/general-settings')}
            )}
            to={'/general-settings'}
          >
            <KTIcon iconName={'gear'} className='fs-2x' />
          </Link>
        </>
      )}

      {/* begin::Quick links */}
      {/* <div className='d-flex align-items-center mb-2'>
      
      
        <div
          className='btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light'
          data-kt-menu-trigger='click'
          data-kt-menu-overflow='true'
          data-kt-menu-placement='top-start'
          data-bs-toggle='tooltip'
          data-bs-placement='right'
          data-bs-dismiss='click'
          title='Quick links'
        >
          <KTIcon iconName='element-plus' className='fs-2 text-lg-1' />
        </div>
       
        <QuickLinks backgroundUrl='/media/misc/pattern-1.jpg' />
      </div> */}
      {/* end::Quick links */}

      {/* begin::Activities */}
      {/* <div className='d-flex align-items-center mb-3'>
       
        <div
          className='btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light'
          data-kt-menu-trigger='click'
          data-kt-menu-overflow='true'
          data-kt-menu-placement='top-start'
          data-bs-toggle='tooltip'
          data-bs-placement='right'
          data-bs-dismiss='click'
          title='Activity Logs'
          id='kt_activities_toggle'
        >
          <KTIcon iconName='chart-simple' className='fs-2 text-lg-1' />
        </div>
        
      </div> */}
      {/* end::Activities */}

      {/* begin::Notifications */}
      {/* <div className='d-flex align-items-center mb-2'>
       
        <div
          className='btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light'
          data-kt-menu-trigger='click'
          data-kt-menu-overflow='true'
          data-kt-menu-placement='top-start'
          data-bs-toggle='tooltip'
          data-bs-placement='right'
          data-bs-dismiss='click'
          title='Notifications'
        >
          <KTIcon iconName='element-11' className='fs-2 text-lg-1' />
        </div>
        
        <HeaderNotificationsMenu backgrounUrl='/media/misc/pattern-1.jpg' />
      </div> */}
      {/* end::Notifications */}

      {/* begin::User */}
      <div className='d-flex align-items-center mb-10' id='kt_header_user_menu_toggle'>
        {/* begin::Menu wrapper */}
        <div
          className='cursor-pointer symbol symbol-40px'
          data-kt-menu-trigger='click'
          data-kt-menu-overflow='false'
          data-kt-menu-placement='top-start'
          title='User profile'
        >
          {currentUser?.avatar ? (
            <img
              src={currentUser?.avatar}
              alt={`${currentUser?.first_name}_${currentUser?.last_name}`}
            />
          ) : (
            <div
              className={clsx(
                'symbol-label fs-3',
                `bg-light-${currentUser?.status ? 'success' : 'danger'}`,
                `text-${currentUser?.status ? 'success' : 'danger'}`
              )}
            >
              {`${currentUser?.first_name?.charAt(0).toUpperCase()}${currentUser?.last_name
                ?.charAt(0)
                .toUpperCase()}`}
            </div>
          )}
        </div>
        {/* end::Menu wrapper */}
        <HeaderUserMenu />
      </div>
      {/* end::User */}
    </div>
  )
}

export {AsideFooter}
