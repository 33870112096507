//React
import React, {useEffect, useRef} from 'react'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

//Helpers
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'

type Props = {
  className: string
  title: string
  bgPath: string
  today: string
  week: string
  month: string
  total: string
}

const IncomeAndExpenseWidget: React.FC<Props> = ({
  className,
  title,
  bgPath,
  today,
  week,
  month,
  total,
}) => {
  const intl = useIntl()

  return (
    <div
      className={`card ${className}`}
      style={{
        backgroundImage: bgPath,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/*begin::Body*/}
      <div className='card-body d-flex flex-column'>
        {/*begin::Wrapper*/}
        <div className='d-flex flex-column mb-7'>
          {/*begin::Title*/}
          <a
            href='#'
            className='text-white text-center fw-bold'
            style={{
              fontSize: 28,
            }}
          >
            {title}
          </a>
          {/*end::Title*/}
        </div>
        {/*end::Wrapper*/}

        {/*begin::Row*/}
        <div className='row g-0'>
          {/*begin::Col*/}
          <div className='col-6'>
            <div className='d-flex align-items-center mb-9 me-2'>
              {/*begin::Symbol*/}
              <div className='symbol symbol-40px me-3'>
                <div className='symbol-label bg-transparent'>
                  <i
                    className='ki-duotone ki-abstract-42 text-gray-900'
                    style={{
                      fontSize: 28,
                    }}
                  >
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>{' '}
                </div>
              </div>
              {/*end::Symbol*/}

              {/*begin::Title*/}
              <div>
                <div
                  className='text-white fw-bold lh-1'
                  style={{
                    fontSize: 30,
                  }}
                >
                  {intl.formatNumber(parseInt(today), {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
                <div
                  className='text-gray-900 fw-bold'
                  style={{
                    fontSize: 17,
                  }}
                >
                  {intl.formatMessage({id: 'DASHBOARD.TODAY'})}
                </div>
              </div>
              {/*end::Title*/}
            </div>
          </div>
          {/*end::Col*/}

          {/*begin::Col*/}
          <div className='col-6'>
            <div className='d-flex align-items-center mb-9 ms-2'>
              {/*begin::Symbol*/}
              <div className='symbol symbol-40px me-3'>
                <div className='symbol-label bg-transparent'>
                  <i
                    className='ki-duotone ki-abstract-45 text-gray-900'
                    style={{
                      fontSize: 28,
                    }}
                  >
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>{' '}
                </div>
              </div>
              {/*end::Symbol*/}

              {/*begin::Title*/}
              <div>
                <div
                  className='text-white fw-bold lh-1'
                  style={{
                    fontSize: 30,
                  }}
                >
                  {intl.formatNumber(parseInt(week), {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
                <div
                  className='text-gray-900 fw-bold'
                  style={{
                    fontSize: 17,
                  }}
                >
                  {intl.formatMessage({id: 'DASHBOARD.WEEK'})}
                </div>
              </div>
              {/*end::Title*/}
            </div>
          </div>
          {/*end::Col*/}

          {/*begin::Col*/}
          <div className='col-6'>
            <div className='d-flex align-items-center me-2'>
              {/*begin::Symbol*/}
              <div className='symbol symbol-40px me-3'>
                <div className='symbol-label bg-transparent'>
                  <i
                    className='ki-duotone ki-abstract-21 text-gray-900'
                    style={{
                      fontSize: 28,
                    }}
                  >
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>{' '}
                </div>
              </div>
              {/*end::Symbol*/}

              {/*begin::Title*/}
              <div>
                <div
                  className='text-white fw-bold lh-1'
                  style={{
                    fontSize: 30,
                  }}
                >
                  {intl.formatNumber(parseInt(month), {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
                <div
                  className='text-gray-900 fw-bold'
                  style={{
                    fontSize: 17,
                  }}
                >
                  {intl.formatMessage({id: 'DASHBOARD.MONTH'})}
                </div>
              </div>
              {/*end::Title*/}
            </div>
          </div>
          {/*end::Col*/}

          {/*begin::Col*/}
          <div className='col-6'>
            <div className='d-flex align-items-center ms-2'>
              {/*begin::Symbol*/}
              <div className='symbol symbol-40px me-3'>
                <div className='symbol-label bg-transparent'>
                  <i
                    className='ki-duotone ki-abstract-44 text-gray-900'
                    style={{
                      fontSize: 28,
                    }}
                  >
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>{' '}
                </div>
              </div>
              {/*end::Symbol*/}

              {/*begin::Title*/}
              <div>
                <div
                  className='text-white fw-bold lh-1'
                  style={{
                    fontSize: 30,
                  }}
                >
                  {intl.formatNumber(parseInt(total), {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
                <div
                  className='text-gray-900 fw-bold'
                  style={{
                    fontSize: 17,
                  }}
                >
                  {intl.formatMessage({id: 'DASHBOARD.TOTAL'})}
                </div>
              </div>
              {/*end::Title*/}
            </div>
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </div>
    </div>
  )
}

export {IncomeAndExpenseWidget}
