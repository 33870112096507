// createApi
import api from '..'

// paths
import {STOPPING_REQUESTS} from '../paths'

// helpers - serializeQueryArgs
import serializeQueryArgs from '../helpers/serializeQueryArgs'
import filterQueryUrlCreater from '../helpers/filterQueryUrlCreater'

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    stoppingRequests: builder.query({
      query: (credentials) => ({
        url: `${STOPPING_REQUESTS}`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        const result = response?.data?.data

        return result
          ? [...result.map(({id}) => ({type: 'STOPPING_REQUESTS', id})), {type: 'STOPPING_REQUESTS', id: 'LIST'}]
          : [{type: 'STOPPING_REQUESTS', id: 'LIST'}]
      },
    }),
    createStoppingRequest: builder.mutation({
      query: (credentials) => ({
        url: STOPPING_REQUESTS + 'create-room',
        method: 'POST',
        body: credentials.endpointData,
      }),
      serializeQueryArgs,
      invalidatesTags: [{type: 'STOPPING_REQUESTS', id: 'LIST'}],
    }),
    updateStoppingRequest: builder.mutation({
      query: (credentials) => ({
        url: `${STOPPING_REQUESTS}${credentials.endpointData.id}/`,
        method: 'PUT',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [{type: 'STOPPING_REQUESTS', id: arg.endpointData.id}]
      },
    }),
   
  }),
})

export const {
  useStoppingRequestsQuery,
  useCreateStoppingRequestMutation,
  useUpdateStoppingRequestMutation,
} = extendedApi
