/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {Link} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1, Search} from '../../../partials'

//Redux - Dispatch - Actions - State
import {useDispatch, useSelector} from 'react-redux'
import {setAuthState, selectCurrentUser, selectToken} from '../../../../app/redux/slice/authSlice'

export function Tab1MenuCustomer() {
  const intl = useIntl()

  const currentUser = useSelector(selectCurrentUser)

  return (
    <>
      <Link to='/dashboard' className='d-flex align-items-center mb-4'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/logos/baselLogoHeader.svg')}
          className='h-40px'
        />
      </Link>
      <div className='d-flex mb-10'>
        <Search />
        {/* begin::Filter */}
        <div className='flex-shrink-0 ms-2'>
          {/* begin::Menu toggle */}
          <button
            type='button'
            className='btn btn-icon btn-bg-light btn-active-icon-primary btn-color-gray-400'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            <KTIcon iconName='filter' className='fs-2' />
          </button>
          {/* end::Menu toggle */}

          <Dropdown1 />
        </div>
        {/* end::Filter */}
      </div>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({id: 'TAB1.PAGES'})}
          </span>
        </div>
      </div>
      <AsideMenuItem
        to='/dashboard'
        icon='home'
        title={intl.formatMessage({id: 'TAB1.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <AsideMenuItem
        to={`/customer-management/customer/${currentUser?.id}/general`}
        icon='category'
        title={intl.formatMessage({id: 'CUSTOMER_MANAGEMENT.GENERAL'})}
        fontIcon='bi-app-indicator'
      /> */}
      <AsideMenuItem
        to={`/customer-management/customer/${currentUser?.id}/profil`}
        icon='profile-circle'
        title={intl.formatMessage({id: 'CUSTOMER_MANAGEMENT.PROFILE'})}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to={`/customer-management/customer/${currentUser?.id}/memberships`}
        icon='gift'
        title={intl.formatMessage({id: 'CUSTOMER_MANAGEMENT.MEMBERSHIPS'})}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to={`/customer-management/customer/${currentUser?.id}/calendar`}
        icon='calendar-tick'
        title={intl.formatMessage({id: 'CUSTOMER_MANAGEMENT.CALENDAR'})}
        fontIcon='bi-app-indicator'
      />

      

      <AsideMenuItem
        to={`/customer-management/customer/${currentUser?.id}/extra`}
        icon='tag'
        title={intl.formatMessage({id: 'CUSTOMER_MANAGEMENT.EXTRA'})}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to={`/customer-management/customer/${currentUser?.id}/health-information`}
        icon='pulse'
        title={intl.formatMessage({id: 'CUSTOMER_MANAGEMENT.HEALTH_INFORMATION'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to={`/customer-management/customer/${currentUser?.id}/package-transactions`}
        icon='minus-circle'
        title={intl.formatMessage({id: 'CUSTOMER_MANAGEMENT.PACKAGE_TRANSACTIONS'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to={`/customer-management/customer/${currentUser?.id}/entering_exiting`}
        icon='pointers'
        title={intl.formatMessage({id: 'CUSTOMER_MANAGEMENT.ENTERING_EXITING'})}
        fontIcon='bi-app-indicator'
      />
      
      <AsideMenuItem
        to={`/customer-management/customer/${currentUser?.id}/reference`}
        icon='people'
        title={intl.formatMessage({id: 'CUSTOMER_MANAGEMENT.REFERENCE'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to={`/customer-management/customer/${currentUser?.id}/discover`}
        icon='eye'
        title={intl.formatMessage({id: 'CUSTOMER_MANAGEMENT.DISCOVER'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to={`/customer-management/customer/${currentUser?.id}/measurement`}
        icon='chart'
        title={intl.formatMessage({id: 'CUSTOMER_MANAGEMENT.MEASUREMENT'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to={`/customer-management/customer/${currentUser?.id}/nutrition`}
        icon='abstract-46'
        title={intl.formatMessage({id: 'CUSTOMER_MANAGEMENT.NUTRITION'})}
        fontIcon='bi-app-indicator'
      />
    </>
  )
}
