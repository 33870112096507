///React
import React, {useEffect, useRef, useState} from 'react'

//Chart
import ApexCharts, {ApexOptions} from 'apexcharts'

//CLSX - Dynamic CSS Class Creator
import clsx from 'clsx'

//React Router Dom
import {Link} from 'react-router-dom'

//Helpers
import {KTIcon} from '../../../../../_metronic/helpers'

//DropDown
import {Dropdown1} from '../../../../../_metronic/partials/content/dropdown/Dropdown1'

//CSS
import {getCSS, getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'

//Theme
import {useThemeMode} from '../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

//Helpers
import dateRangeName from '../../../../helpers/DateRangeName'

//DateRange
import {DateRange} from '../../../../components/dateRange'

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
} from 'date-fns'

type Props = {
  className: string
  series?: number[]
  dateRange: {
    startDate: Date
    endDate: Date
    key: string
  }
  setDataRange: (dateRange: {startDate: Date; endDate: Date; key: string}) => void
}

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
}

const Statistics: React.FC<Props> = ({className, series, dateRange, setDataRange}) => {
  const intl = useIntl()

  const [dateRangeVisible, setDataRangeVisible] = useState(false)

  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, getChartOptions({intl, series}))
    if (chart) {
      chart.render()
    }

    return chart
  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <button
                className={clsx(
                  'nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1',
                  {
                    active:
                      isSameDay(dateRange.startDate, defineds.startOfToday) &&
                      isSameDay(dateRange.endDate, defineds.endOfToday),
                  }
                )}
                onClick={() => {
                  setDataRange({
                    startDate: defineds.startOfToday,
                    endDate: defineds.endOfToday,
                    key: 'selection',
                  })
                }}
              >
                {intl.formatMessage({id: 'DATE.TODAY'})}
              </button>
            </li>
            <li className='nav-item'>
              <button
                className={clsx(
                  'nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1',
                  {
                    active:
                      isSameDay(dateRange.startDate, defineds.startOfWeek) &&
                      isSameDay(dateRange.endDate, defineds.endOfWeek),
                  }
                )}
                onClick={() => {
                  setDataRange({
                    startDate: defineds.startOfWeek,
                    endDate: defineds.endOfWeek,
                    key: 'selection',
                  })
                }}
              >
                {intl.formatMessage({id: 'DATE.THIS_WEEK'})}
              </button>
            </li>
            <li className='nav-item'>
              <button
                className={clsx(
                  'nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1',
                  {
                    active:
                      isSameDay(dateRange.startDate, defineds.startOfMonth) &&
                      isSameDay(dateRange.endDate, defineds.endOfMonth),
                  }
                )}
                onClick={() => {
                  setDataRange({
                    startDate: defineds.startOfMonth,
                    endDate: defineds.endOfMonth,
                    key: 'selection',
                  })
                }}
              >
                {intl.formatMessage({id: 'DATE.THIS_MONTH'})}
              </button>
            </li>
          </ul>
        </div>
        <div className='form-inline'>
          <div
            className='form-select form-select-solid fw-bolder form-inline'
            onClick={() => {
              setDataRangeVisible(true)
            }}
          >
            <span className='d-flex' style={{width: '100%'}}>
              <KTIcon iconName='calendar' className='fs-1' />
              <span style={{marginLeft: 5}}>
                {dateRangeName({intl, range: dateRange, onlyDate: true})}
              </span>
            </span>
          </div>
          <DateRange
            className='filterdaterange'
            visible={dateRangeVisible}
            value={dateRange}
            onCancel={() => {
              setDataRangeVisible(false)
            }}
            onSelect={(range) => {
              setDataRange(range)
              setDataRangeVisible(false)
            }}
            right={false}
          />
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='row'>
          <div className='col-6'>
            <div className='d-flex align-items-center my-12 p-4 border-gray-300 border-dashed'>
              {/* begin::Symbol */}
              <div className='symbol symbol-45px w-40px me-5'>
                <span className='symbol-label bg-lighten'>
                  <KTIcon iconName='time' className='fs-1 text-success' />
                </span>
              </div>
              {/* end::Symbol */}
              {/* begin::Description */}
              <div className='d-flex align-items-center flex-wrap w-100'>
                {/* begin::Title */}
                <div className='mb-1 pe-3 flex-grow-1'>
                  <span className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                    {intl.formatMessage({id: 'DASHBOARD.SESSION'})}
                  </span>
                </div>
                {/* end::Title */}
                {/* begin::Label */}
                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-5 text-gray-800 pe-1'>253</div>

                  <span className='badge badge-success fs-8 fw-bold'>95%</span>
                </div>
                {/* end::Label */}
              </div>
              {/* end::Description */}
            </div>
            <div className='d-flex align-items-center my-12 p-4 border-gray-300 border-dashed'>
              {/* begin::Symbol */}
              <div className='symbol symbol-45px w-40px me-5'>
                <span className='symbol-label bg-lighten'>
                  <KTIcon iconName='calendar' className='fs-1 text-success' />
                </span>
              </div>
              {/* end::Symbol */}
              {/* begin::Description */}
              <div className='d-flex align-items-center flex-wrap w-100'>
                {/* begin::Title */}
                <div className='mb-1 pe-3 flex-grow-1'>
                  <span className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                    {intl.formatMessage({id: 'DASHBOARD.APPOINTMENT'})}
                  </span>
                </div>
                {/* end::Title */}
                {/* begin::Label */}
                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-5 text-gray-800 pe-1'>253</div>

                  <span className='badge badge-success fs-8 fw-bold'>95%</span>
                </div>
                {/* end::Label */}
              </div>
              {/* end::Description */}
            </div>
            <div className='d-flex align-items-center my-12 p-4 border-gray-300 border-dashed'>
              {/* begin::Symbol */}
              <div className='symbol symbol-45px w-40px me-5'>
                <span className='symbol-label bg-lighten'>
                  <KTIcon iconName='user-tick' className='fs-1 text-success' />
                </span>
              </div>
              {/* end::Symbol */}
              {/* begin::Description */}
              <div className='d-flex align-items-center flex-wrap w-100'>
                {/* begin::Title */}
                <div className='mb-1 pe-3 flex-grow-1'>
                  <span className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                    {intl.formatMessage({id: 'DASHBOARD.PARTICIPATION'})}
                  </span>
                </div>
                {/* end::Title */}
                {/* begin::Label */}
                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-5 text-gray-800 pe-1'>253</div>

                  <span className='badge badge-success fs-8 fw-bold'>95%</span>
                </div>
                {/* end::Label */}
              </div>
              {/* end::Description */}
            </div>
          </div>

          <div className='col-6'>
            <div ref={chartRef} style={{height: '350px'}} />
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {Statistics}

function getChartOptions({intl, series = [0, 0, 0]}): ApexOptions {
  return {
    series,
    chart: {
      height: 500,
      type: 'donut',
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: true,
        offsetX: 0,
        offsetY: 0,
        customScale: 1,

        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 10,
        },
        donut: {
          size: '65%',
          background: 'transparent',
          labels: {
            show: false,
            name: {
              show: true,
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              color: undefined,
              offsetY: -10,
              formatter: function (val) {
                return val
              },
            },
            value: {
              show: true,
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              color: 'yellow',
              offsetY: 16,
              formatter: function (val) {
                return val
              },
            },
            total: {
              show: false,
              showAlways: false,
              label: 'Total',
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              color: '#373d3f',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b
                }, 0)
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    fill: {
      type: 'gradient',
    },
    legend: {
      formatter: function (val, opts) {
        return val + ' - ' + opts.w.globals.series[opts.seriesIndex]
      },
      position: 'bottom',
      floating: false,

      markers: {
        width: 8,
        height: 18,
      },
    },
    title: {
      text: 'Ders Performans Oranı',
      align: 'center',
      style: {
        fontSize: '15px',
        fontWeight: 'bold',
      },
    },
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      colors: undefined,
      width: 2,
      dashArray: 0,
    },

    colors: ['#3E97FF', '#1BC5BD', '#7239EA'],
    labels: [
      intl.formatMessage({id: 'DASHBOARD.SESSION'}),
      intl.formatMessage({id: 'DASHBOARD.APPOINTMENT'}),
      intl.formatMessage({id: 'DASHBOARD.PARTICIPATION'}),
    ],

    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
  }
}
