//React
import {useState, useEffect} from 'react'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

//Data Fsn
import {addDays} from 'date-fns'

//Helpers
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'

//Layout
import {PageLink, PageTitle} from '../../../_metronic/layout/core'

//Redux ToolKit API
import {useSummaryQuery, useLecturesPerformanceRatesQuery} from '../../api/endpoints/summary'

//Redux - Dispatch - Actions - State
import {useDispatch, useSelector} from 'react-redux'
import {selectCurrentUser} from '../../redux/slice/authSlice'

//Date Format
import {dateFormat} from '../../i18n/Metronici18n'

import AdminDashboard from './AdminDashboard'

import CustomerDashboard from '../customerManagement/general'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const DashboardWrapper = () => {
  const intl = useIntl()

  const currentUser = useSelector(selectCurrentUser)

  const is_superuser = currentUser?.is_superuser
  const is_staff = currentUser?.is_staff
  const is_customer = currentUser?.is_customer

  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>
        {intl.formatMessage({id: 'DASHBOARD.PAGE_TITLE'})}
      </PageTitle>
      {is_superuser === true || is_staff === true ? <AdminDashboard />:null }
      {is_customer === true ? <CustomerDashboard type='overview' currentUserId = {currentUser?.id} />:null }
      
    </>
  )
}

export {DashboardWrapper}
