// createApi
import api from '..'

// paths
import {ROOM} from '../paths'

// helpers - serializeQueryArgs
import serializeQueryArgs from '../helpers/serializeQueryArgs'
import filterQueryUrlCreater from '../helpers/filterQueryUrlCreater'

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    ROOMs: builder.query({
      query: (credentials) => ({
        url: filterQueryUrlCreater({baseUrl: ROOM, endpointData: credentials.endpointData}),
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        const result = response?.data?.data

        return result
          ? [...result.map(({id}) => ({type: 'ROOMS', id})), {type: 'ROOMS', id: 'LIST'}]
          : [{type: 'ROOMS', id: 'LIST'}]
      },
    }),
    createROOM: builder.mutation({
      query: (credentials) => ({
        url: ROOM + 'create-room',
        method: 'POST',
        body: credentials.endpointData,
      }),
      serializeQueryArgs,
      invalidatesTags: [{type: 'ROOMS', id: 'LIST'}],
    }),
    updateROOM: builder.mutation({
      query: (credentials) => ({
        url: `${ROOM}${credentials.endpointData.id}/`,
        method: 'PUT',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [{type: 'ROOMS', id: arg.endpointData.id}]
      },
    }),
    deleteROOM: builder.mutation({
      query: (credentials) => ({
        url: `${ROOM}${credentials.endpointData.id}/`,
        method: 'DELETE',
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [{type: 'ROOMS', id: arg.endpointData.id}]
      },
    }),
  }),
})

export const {
  useROOMsQuery,
  useCreateROOMMutation,
  useUpdateROOMMutation,
  useDeleteROOMMutation,
} = extendedApi
