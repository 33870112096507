// Redux Toolkit
import {isRejectedWithValue, isFulfilled} from '@reduxjs/toolkit'

// React-Toastify
import {toast} from 'react-toastify'

// i18n - react-intl - internationalization - locale - localization
import {createIntl, createIntlCache} from 'react-intl'
import {getConfig} from '../../i18n/Metronici18n'
import {allMessages} from '../../i18n/i18nProvider'

//Logout
import {logout} from '../slice/authSlice'

const RtkQueryErrorLogger = (api: any) => (next: any) => (action: any) => {
  const sle = getConfig()

  const cache = createIntlCache()

  const intl = createIntl(
    {
      locale: sle.selectedLang,
      messages: (allMessages as Record<string, Record<string, string>>)[sle.selectedLang],
    },
    cache
  )

  if (isFulfilled(action)) {
    const data = action?.payload
    const message = data?.success === true ? JSON.stringify(data?.message) : ''

    const {toastSuccessMessageStatus = false} = action?.meta?.arg?.originalArgs
    if (toastSuccessMessageStatus) {
      toast.success(<MessageContainer type='success' message={message} />)
    }
  }
  if (isRejectedWithValue(action)) {
    const data = action?.payload?.data
    const message =
      data?.success === false || data?.success === 'False'
        ? JSON.stringify(data?.message)
        : JSON.stringify(action?.payload?.error)

    const {toastErrorMessageStatus = false} = action?.meta?.arg?.originalArgs

    if (toastErrorMessageStatus) {
      toast.error(<MessageContainer type='error' message={message} />)
    }

    if (action?.payload?.status === 401) {
      api.dispatch(logout())
      toast.error(
        <MessageContainer
          type='error'
          message={intl.formatMessage({id: 'MESSAGE.INVALID_SESSION'})}
        />
      )
    }
  }

  return next(action)
}

interface MessageContainerProps {
  type?: 'success' | 'error'
  message: string
}

const MessageContainer: React.FC<MessageContainerProps> = ({type = 'success', message}) => {
  const sle = getConfig()

  const cache = createIntlCache()

  const intl = createIntl(
    {
      locale: sle.selectedLang,
      messages: (allMessages as Record<string, Record<string, string>>)[sle.selectedLang],
    },
    cache
  )

  const title =
    type === 'success'
      ? intl.formatMessage({id: 'MESSAGE.SUCCESS.TITLE'})
      : intl.formatMessage({id: 'MESSAGE.ERROR.TITLE'})

  return message === '' ? (
    <div className='alert  d-flex align-items-center  pb-0'>
      <div className='d-flex flex-column'>
        <h5 className='m-0 p-0'>{title}</h5>
      </div>
    </div>
  ) : (
    <div className='alert  d-flex align-items-center pb-0'>
      <div className='d-flex flex-column'>
        <h5 className={'mb-1'}>{title}</h5>
        <span>{message}</span>
      </div>
    </div>
  )
}

export default RtkQueryErrorLogger
