//React
import {useState, useEffect} from 'react'

//React Router Dom
import {Link, useLocation, useParams, useNavigate} from 'react-router-dom'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

//Data Fsn
import {addDays} from 'date-fns'

//Helpers
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'

//Redux - Dispatch - Actions - State
import {useDispatch, useSelector} from 'react-redux'
import {selectInitialData} from '../../../redux/slice/initialDataSlice'
import {selectCurrentUser} from '../../../redux/slice/authSlice'

//Redux ToolKit API
import {useGeneralCustormerQuery} from '../../../api/endpoints/customer'

//Custom Widget
import {
  Appointment,
  CustomerDetail,
  Statistics,
  IncomeAndExpenseWidget,
  MeasurementDates,
  TimeLine,
  FinancialSummary,
  Permissions,
} from './components'

interface Props {
  type: 'general' | 'overview'
  currentUserId: number | null | undefined
}

const General: React.FC<Props> = ({type = 'general', currentUserId}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const params = useParams()
  const userId = type == 'general' ? params?.userId : currentUserId
  const [dateRange, setDataRange] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: 'selection',
  })
  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])
  const initialdata = useSelector(selectInitialData)

  const currentUser = useSelector(selectCurrentUser)

  const motivations =
  initialdata?.motivations == undefined ? [] : initialdata?.motivations.filter((x) => x.type == 1)


  const {data, isLoading, isFetching, isError} = useGeneralCustormerQuery(
    {
      endpointData: {
        id: userId,
      },
      toastSuccessMessageStatus: false,
      toastErrorMessageStatus: true,
    },
    {
      pollingInterval: 60000,
    }
  )

  const appointment = data?.data?.data?.appointment
  const sale = data?.data?.package?.satin_alinan
  const financialSummary = data?.data?.data?.account_statement[0]
  const detail = data?.data?.data?.detail[0]

  const body_analysis = data?.data?.data?.body_analysis

  return (
    <>
      <div className='row gy-10 gx-xl-10 mb-5'>
      <div className='col-xl-4'>
          <div
            className='card card-xxl-stretch mb-5 d-flex flex-center align-items-center'
            style={{
              backgroundColor: '#3E97FF',
              backgroundImage: `url('${toAbsoluteUrl(`/media/basel/jpeg/motive${currentUser?.company_type}0.jpeg`)}')`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              minHeight: 500,
              padding: 50,
            }}
          >
            <span className='text-center fs-2x text-white fw-bold my-10' style={{
              textShadow:'1px 1px 2px black'
            }}>
              {motivations.length > 0
                ? motivations[Math.floor(Math.random() * motivations.length)].message
                : ''}
            </span>
          </div>
        </div>

        <div className='col-xl-4'>
          <TimeLine className='card-xxl-stretch mb-5' type={type} currentUserId={currentUserId} />
        </div>
        <div className='col-xl-4'>
          {type == 'general' ? (
            <CustomerDetail
              className='card-xl-stretch mb-5'
              title={intl.formatMessage({id: 'DASHBOARD.PACKAGE_SALE.TITLE'})}
              bgPath={`url('${toAbsoluteUrl('/media/basel/svg/satin_alinan_bg.svg')}')`}
              number_of_guests={
                detail?.number_of_guests == undefined ? '0' : (detail?.number_of_guests as string)
              }
              remaining_time_to_birthday={
                detail?.remaining_time_to_birthday == undefined
                  ? '0'
                  : detail?.remaining_time_to_birthday
              }
              number_of_packages={
                detail?.number_of_packages == undefined
                  ? '0'
                  : (detail?.number_of_packages as string)
              }
            />
          ) : (
            <div
              className='card card-xxl-stretch mb-5 d-flex flex-end align-items-center'
              style={{
                backgroundColor: '#3E97FF',
                backgroundImage: `url('${toAbsoluteUrl(`/media/basel/jpeg/motive${currentUser?.company_type}1.jpeg`)}')`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <span className='text-center fs-4x text-white fw-bold my-10'>
                {intl.formatMessage({
                  id: 'CUSTOMER_MANAGEMENT.GENERAL.OPPORTUNITIES',
                })}
              </span>

              <button
                className='btn btn-lg btn-light my-10'
                onClick={() => {
                  navigate(`/customer-management/customer/${userId}/memberships`)
                }}
              >
                {intl.formatMessage({
                  id: 'FORM.BUTTON.DETAIL',
                })}
              </button>
            </div>
          )}
        </div>
      </div>
      <div className='row gy-10 gx-xxl-10 mb-5'>
        {/* begin::Col */}
        <div className='col-xxl-4'>
          <Appointment className='card-xxl-stretch mb-5' appointment={appointment} />
        </div>
        {/* end::Col */}
        {/* begin::Col */}

        <div className='col-xxl-4'>
          <FinancialSummary
            className='card-xxl-stretch mb-5'
            color='primary'
            total_amount={
              financialSummary?.total_amount == undefined
                ? '0'
                : (financialSummary?.total_amount as string)
            }
            overdue_debt={
              financialSummary?.overdue_debt == undefined
                ? '0'
                : (financialSummary?.overdue_debt as string)
            }
            due_debt={
              financialSummary?.due_debt == undefined ? '0' : (financialSummary?.due_debt as string)
            }
            unpaid_installment_amount={
              financialSummary?.unpaid_installment_amount == undefined
                ? '0'
                : (financialSummary?.unpaid_installment_amount as string)
            }
            due_installment_amount={
              financialSummary?.due_installment_amount == undefined
                ? '0'
                : (financialSummary?.due_installment_amount as string)
            }
          />
        </div>
        <div className='col-xxl-4'>
          <MeasurementDates className='card-xxl-stretch mb-5' body_analysis={body_analysis} />
          {/* <Permissions className='card-xxl-stretch mb-5' type={type} currentUserId={currentUserId}/> */}
        </div>
        {/* end::Col */}
      </div>
    </>
  )
}

export default General
