// createApi
import api from '..'

// paths
import {COMPANY} from '../paths'

// helpers - serializeQueryArgs
import serializeQueryArgs from '../helpers/serializeQueryArgs'
import filterQueryUrlCreater from '../helpers/filterQueryUrlCreater'

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    company: builder.query({
      query: (credentials) => ({
        url: `${COMPANY}`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: ['COMPANY'],
    }),

    updateCompany: builder.mutation({
      query: (credentials) => ({
        url: `${COMPANY}`,
        method: 'PUT',
        body: credentials.endpointData,
      }),
      serializeQueryArgs,
      invalidatesTags: ['COMPANY'],
    }),
  }),
})

export const {useCompanyQuery, useUpdateCompanyMutation} = extendedApi
