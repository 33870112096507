//React
import React, {FC, useState, useEffect, useRef} from 'react'

//Date Range
import {DateRangePicker} from 'react-date-range'

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
} from 'date-fns'

//Date-fsn/locale
import {enUS, tr} from 'date-fns/locale'

//getLocaleConfig
import {getConfig} from '../../i18n/Metronici18n'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

//CSS
import 'react-date-range/dist/styles.css' // main css file
import './css/reactdatarangepicker.css' // theme css file

interface Props {
  className: string
  visible: boolean
  value: {
    startDate: Date
    endDate: Date
    key: string
  }
  onSelect: (range: any) => void
  onCancel: () => void
  right?: boolean
}

interface DateRangeProps {
  startDate: Date
  endDate: Date
}

interface StaticRange {
  label: string
  range: () => DateRangeProps
  isSelected: (range: DateRangeProps) => boolean
}

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
}

const staticRangeHandler: StaticRange = {
  label: 'Custom',
  range: () => ({startDate: defineds.startOfToday, endDate: defineds.endOfToday}),
  isSelected(range) {
    const definedRange = this.range()
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    )
  },
}

function createStaticRanges(ranges: StaticRange[]): StaticRange[] {
  return ranges.map((range) => ({...staticRangeHandler, ...range}))
}

const DateRange: React.FC<Props> = ({
  className = 'daterange',
  visible,
  value,
  onSelect,
  onCancel,
  right = true,
}) => {
  const intl = useIntl()
  const lang = getConfig()
  const divRef = useRef<HTMLDivElement>(null)

  const defaultStaticRanges: StaticRange[] = createStaticRanges([
    {
      label: intl.formatMessage({id: 'DATE.TODAY'}),
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
      isSelected(range) {
        const definedRange = this.range()
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        )
      },
    },
    {
      label: intl.formatMessage({id: 'DATE.YESTERDAY'}),
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
      isSelected(range) {
        const definedRange = this.range()
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        )
      },
    },
    {
      label: intl.formatMessage({id: 'DATE.THIS_WEEK'}),
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek,
      }),
      isSelected(range) {
        const definedRange = this.range()
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        )
      },
    },
    {
      label: intl.formatMessage({id: 'DATE.LAST_WEEK'}),
      range: () => ({
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek,
      }),
      isSelected(range) {
        const definedRange = this.range()
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        )
      },
    },
    {
      label: intl.formatMessage({id: 'DATE.THIS_MONTH'}),
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
      }),
      isSelected(range) {
        const definedRange = this.range()
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        )
      },
    },
    {
      label: intl.formatMessage({id: 'DATE.LAST_MONTH'}),
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth,
      }),
      isSelected(range) {
        const definedRange = this.range()
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        )
      },
    },
    //   {
    //     label: 'Tüm Zamanlar',
    //     range: () => ({
    //       startDate: new Date(),
    //       endDate: new Date(),
    //     }),
    //     isSelected(range) {
    //       const definedRange = this.range()
    //       return false
    //     },
    //   },
  ])

  const defaultInputRanges = [
    {
      label: intl.formatMessage({id: 'DATE.DAYS_UP_TO_TODAY'}),
      range(value) {
        return {
          startDate: addDays(defineds.startOfToday, (Math.max(Number(value), 1) - 1) * -1),
          endDate: defineds.endOfToday,
        }
      },
      getCurrentValue(range) {
        if (!isSameDay(range.endDate, defineds.endOfToday)) return '-'
        if (!range.startDate) return '∞'
        return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1
      },
    },
    {
      label: intl.formatMessage({id: 'DATE.DAYS_STARTING_TODAY'}),
      range(value) {
        const today = new Date()
        return {
          startDate: today,
          endDate: addDays(today, Math.max(Number(value), 1) - 1),
        }
      },
      getCurrentValue(range) {
        if (!isSameDay(range.startDate, defineds.startOfToday)) return '-'
        if (!range.endDate) return '∞'
        return differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1
      },
    },
  ]
  const [selectionRange, setSelectionRange] = useState(
    value.key == 'selection'
      ? value
      : {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        }
  )

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection)
  }
  const [bottom, setBottom] = useState(0)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const handleResize = () => {
    console.log(window.innerHeight)
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    // Component mount olduğunda ve her window boyutu değiştiğinde handleResize fonksiyonunu çağır
    window.addEventListener('resize', handleResize)

    // Component unmount olduğunda event listener'ı kaldır
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setSelectionRange(
      value.key == 'selection'
        ? value
        : {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
          }
    )
  }, [visible])

  useEffect(() => {
    if (divRef.current != null && visible) {
      const divBottom = divRef.current.getBoundingClientRect().bottom

      const windowHeight = window.innerHeight

      const distanceToBottom = windowHeight - (divBottom - 40)

      if (distanceToBottom < 0) {
        setBottom(distanceToBottom / 2)
      }
    }
  }, [visible])

  return (
    <div
      className={className}
      ref={divRef}
      style={
        right
          ? {
              display: visible ? 'block' : 'none',
              position: 'absolute',
              backgroundColor: 'white',
              boxShadow: '0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
              right: 0,
              padding: 10,
              margin: 10,
              borderRadius: '0.475rem',
              zIndex: 10,
              bottom: bottom == 0 ? undefined : bottom,
            }
          : {
              display: visible ? 'block' : 'none',
              position: 'absolute',
              backgroundColor: 'white',
              boxShadow: '0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
              left: 0,
              padding: 10,
              margin: 10,
              borderRadius: '0.475rem',
              zIndex: 10,
              bottom: bottom == 0 ? undefined : bottom,
            }
      }
    >
      <DateRangePicker
        onChange={handleSelect}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={[selectionRange]}
        direction={windowWidth > 992 ? 'horizontal' : 'vertical'}
        staticRanges={defaultStaticRanges}
        inputRanges={defaultInputRanges}
        locale={lang.selectedLang == 'tr' ? tr : enUS}
        color='#1BC5BD'
        rangeColors={['#1BC5BD']}
      />

      <div className='d-flex justify-content-end'>
        <a
          type='button'
          onClick={onCancel}
          className='btn btn-sm btn-light btn-active-light-primary fw-bold me-2 px-6'
        >
          {intl.formatMessage({id: 'FORM.BUTTON.CANCEL'})}
        </a>
        <a
          type='button'
          onClick={() => {
            onSelect(selectionRange)
          }}
          className='btn btn-sm btn-primary fw-bold px-6'
        >
          {intl.formatMessage({id: 'FORM.BUTTON.SELECT'})}
        </a>
      </div>
    </div>
  )
}

export {DateRange}
