//React Router Dom
import {Route, Routes} from 'react-router-dom'

//Layout
import {Layout} from '../pages/auth/components/layout'

//Pages
import {SingIn, SingUp,ForgotPassword,ResetPassword} from '../pages/auth'

const AuthRoutes = () => (
  <Routes>
    <Route element={<Layout />}>
      <Route path='singin' element={<SingIn />} />
      <Route path='singup' element={<SingUp />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password' element={<ResetPassword />} />
      <Route index element={<SingIn />} />
    </Route>
  </Routes>
)

export {AuthRoutes}
