//React
import {FC} from 'react'

//Helpers
import {KTIcon} from '../../../_metronic/helpers'

//CLSX - Dynamic CSS Class Creator
import clsx from 'clsx'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

//Redux - Dispatch - Actions - State
import {
  useNotificationsQuery,
  useUpdateNotificationMutation,
  useLazyNotificationsMarkAllQuery,
} from '../../api/endpoints/notification'

//Date Fns
import {dateFormat} from '../../i18n/Metronici18n'

const DrawerNotification: FC = () => {
  const intl = useIntl()
  const [request] = useUpdateNotificationMutation()
  const [requestMarkAll] = useLazyNotificationsMarkAllQuery()
  const {data, isLoading, isFetching} = useNotificationsQuery({
    endpointData: {
      limit: -1,
    },
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })

  const SET_READ = async (id) => {
    const sendFormdata = new FormData()
    sendFormdata.append('is_read', 'true')
    try {
      await request({
        endpointData: {
          id: id,
          formData: sendFormdata,
        },
        toastSuccessMessageStatus: false,
        toastErrorMessageStatus: true,
      }).unwrap()
    } catch (error) {
      console.log(error)
    }
  }

  const SET_MARK_ALL = async () => {
    const sendFormdata = new FormData()
    sendFormdata.append('is_read', 'true')
    try {
      await requestMarkAll({
        endpointData: {},
        toastSuccessMessageStatus: false,
        toastErrorMessageStatus: true,
      }).unwrap()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div
      id='kt_drawer_notification'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='notification'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'md': '500px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_drawer_notification_toggle'
      data-kt-drawer-close='#kt_drawer_notification_close'
    >
      <div className='card w-100 rounded-0' id='kt_drawer_notification_messenger'>
        <div className='card-header pe-5' id='kt_drawer_notification_messenger_header'>
          <div className='card-title'>
            <div className='d-flex justify-content-center flex-column me-3'>
              <span className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>
                {intl.formatMessage({id: 'FORM.LABEL.NOTIFICATIONS'})}
              </span>

              <div
                className='mb-0 lh-1'
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  SET_MARK_ALL()
                }}
              >
                <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                <span className='fs-7 fw-bold text-gray-500'>
                  {' '}
                  {intl.formatMessage({id: 'FORM.LABEL.NOTIFICATIONS_MARK_ALL'})}
                </span>
              </div>
            </div>
          </div>

          <div className='card-toolbar'>
            <div
              className='btn btn-sm btn-icon btn-active-light-primary'
              id='kt_drawer_notification_close'
            >
              <KTIcon iconName='cross' className='fs-2' />
            </div>
          </div>
        </div>

        <div className='card-body' id={'kt_drawer_notification_messenger_body'}>
          <div
            className={clsx('scroll-y me-n5 pe-5', {'h-300px h-lg-auto': false})}
            data-kt-element='messages'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies={
              '#kt_drawer_notification_messenger_header, #kt_drawer_notification_messenger_footer'
            }
            data-kt-scroll-wrappers={'#kt_drawer_notification_messenger_body'}
            data-kt-scroll-offset={'0px'}
          >
            {data?.data?.data === undefined
              ? null
              : [...data?.data?.data].reverse().map((message) => {
                  return (
                    <div
                      key={message?.id}
                      className='accordion mb-2'
                      id={`kt_accordion_${message?.id}`}
                    >
                      <div className='accordion-item'>
                        <h2
                          className='accordion-header'
                          id={`kt_accordion_${message?.id}_header_${message?.id}`}
                        >
                          <button
                            className='accordion-button fs-4 fw-bold collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target={`#kt_accordion_${message?.id}_body_${message?.id}`}
                            aria-expanded='false'
                            aria-controls={`kt_accordion_${message?.id}_body_${message?.id}`}
                            onClick={() => {
                              if (message?.is_read == false) {
                                SET_READ(message?.id)
                              }
                            }}
                          >
                            {/* begin::Icon */}
                            <span className='text-success me-5'>
                              <KTIcon
                                iconName={
                                  message?.is_read === true ? 'notification' : 'notification-on'
                                }
                                className={`text-${
                                  message?.is_read === true ? 'success' : 'warning'
                                } fs-1 me-5`}
                              />
                            </span>
                            {/* end::Icon */}
                            {/* begin::Title */}
                            <div className='flex-grow-1 me-2'>
                              <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
                                {message?.header}
                              </a>
                              <span className='text-muted fw-semibold d-block'>
                                {dateFormat(new Date(message?.created_at), 'dd.MM.yyyy')}
                              </span>
                            </div>
                            {/* end::Title */}
                          </button>
                        </h2>
                        <div
                          id={`kt_accordion_${message?.id}_body_${message?.id}`}
                          className='accordion-collapse collapse'
                          aria-labelledby={`kt_accordion_${message?.id}_header_${message?.id}`}
                          data-bs-parent={`#kt_accordion_${message?.id}`}
                        >
                          <div className='accordion-body'>
                            <strong>{message?.content}</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DrawerNotification
