//redux/index.js

//@reduxjs/toolkit
import {configureStore} from '@reduxjs/toolkit'

//API
import api from '../api'

//MiddleWare
import {RtkQueryErrorLogger} from './middleware'

//Slice
import initialDataReducer from './slice/initialDataSlice'
import authReducer from './slice/authSlice'

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    initialDataReducer,
    authReducer,
    
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(api.middleware).concat(RtkQueryErrorLogger),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
