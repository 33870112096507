import React, {FC, createContext, useContext, ReactNode} from 'react'

import {createIntl, createIntlCache} from 'react-intl'
//react-phone-number-input
import PhoneInput, {getCountries, getCountryCallingCode} from 'react-phone-number-input'
import {allMessages} from './i18nProvider'
import {format, formatDistanceToNow, startOfWeek, endOfWeek} from 'date-fns'
import {enUS, tr} from 'date-fns/locale'
import trPhoneInput from 'react-phone-number-input/locale/tr.json'
import enPhoneInput from 'react-phone-number-input/locale/en.json'

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

type WithChildren = {
  children?: ReactNode
}

type Props = {
  selectedLang: 'tr' | 'en'
}
const initialState: Props = {
  selectedLang: 'tr',
}
const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

export function getConfig(): Props {
  const lb = navigator.language
  const crL = Object.keys(allMessages)

  const ls = localStorage.getItem(I18N_CONFIG_KEY)

  if (ls) {
    try {
      return JSON.parse(ls) as Props
    } catch (er) {
      console.error(er)
    }
  } else if (lb !== undefined && lb !== null && lb != '') {
    const firstTwoCharacters = lb.substring(0, 2)

    if (crL.indexOf(firstTwoCharacters) !== -1) {
      return {
        selectedLang: firstTwoCharacters,
      } as Props
    } else {
      return initialState
    }
  }

  return initialState
}

// Side effect
export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
  window.location.reload()
}

export function dateFormat(date: Date, formatStr = 'PP') {
  try {
    const lang = getConfig()

    return format(date, formatStr, {
      locale: lang.selectedLang == 'tr' ? tr : enUS,
    })
  } catch (error) {
    return '-'
  }
}

export function startOfWeekAndEndOfWeek(date: Date, ) {
  const lang = getConfig()

    const now = date
    const startOfThisWeek = startOfWeek(now, {locale: lang.selectedLang == 'tr' ? tr : enUS})
    const endOfThisWeek = endOfWeek(now, {locale: lang.selectedLang == 'tr' ? tr : enUS})

    

    return {startOfThisWeek, endOfThisWeek}
}

export function remainingTimeString(date: Date, formatStr = 'PP') {
  try {
    const lang = getConfig()

    return formatDistanceToNow(date, {
      addSuffix: true,
      locale: lang.selectedLang == 'tr' ? tr : enUS,
    })
  } catch (error) {
    return '-'
  }
}

const I18nContext = createContext<Props>(initialState)

const useLang = () => {
  return useContext(I18nContext).selectedLang
}

const MetronicI18nProvider: FC<WithChildren> = ({children}) => {
  const lang = getConfig()
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
}

const CustomPhoneInput = (props) => {
  const lang = getConfig()
  return (
    <PhoneInput
      defaultCountry={lang.selectedLang == 'en' ? 'US' : 'TR'}
      labels={lang.selectedLang == 'en' ? enPhoneInput : trPhoneInput}
      {...props}
    />
  )
}

const CustomCountrySelect = (props) => {
  const lang = getConfig()
  const labels = lang.selectedLang == 'en' ? enPhoneInput : trPhoneInput

  const cache = createIntlCache()

  const intl = createIntl(
    {
      locale: lang.selectedLang,
      messages: (allMessages as Record<string, Record<string, string>>)[lang.selectedLang],
    },
    cache
  )

  return (
    <select {...props}>
      <option value=''>{intl.formatMessage({id: 'FORM.PLACEHOLDER.SELECT'})}</option>
      {getCountries().map((country) => (
        <option key={country} value={country}>
          {labels[country]}
        </option>
      ))}
    </select>
  )
}

export {MetronicI18nProvider, useLang, CustomPhoneInput, CustomCountrySelect}
