//React
import React, {FC, useState, useEffect} from 'react'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

//React-Router-Dom
import {useLocation, useNavigate} from 'react-router-dom'

//CLSX - Dynamic CSS Class Creator
import clsx from 'clsx'

//Helper
import {KTIcon} from '../../../../_metronic/helpers'

interface ModalProps {
  modalVisible: boolean
  desc?: boolean
  onCloseModal: () => void
  onContinue: (text) => void
}

const WarningModal: React.FC<ModalProps> = ({
  onCloseModal,
  onContinue,
  modalVisible = false,
  desc = false,
}) => {
  const intl = useIntl()

  const [text, setText] = useState('')

  const [fade, setFade] = useState(false)
  useEffect(() => {
    if (modalVisible) {
      setText('')
      setTimeout(() => setFade(true), 100)
    } else {
      setFade(false)
    }
  }, [modalVisible])
  const location = useLocation()
  const modalID = `modal_${location.pathname.replace(/\//g, '_')}_warning`

  return (
    <>
      <div
        className={clsx('modal fade d-block', {show: fade})}
        id={`${modalID}`}
        role='dialog'
        tabIndex={-1}
        aria-modal={'true'}
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content bg-light-warning py-10 px-10 px-lg-20 mb-10'>
            <button
              className='position-absolute top-0 end-0 m-2 btn btn-icon btn-icon-warning'
              onClick={onCloseModal}
              style={{cursor: 'pointer'}}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
            {/* begin::Modal body */}
            <div className='modal-body'>
              <div className='d-flex flex-center'>
                <KTIcon iconName='information' className='fs-5tx text-warning mb-5' />
              </div>
              <div className='text-center'>
                <h1 className='fw-bold mb-5'>{intl.formatMessage({id: 'MODAL.WARNING.TITLE'})}</h1>

                <div className='separator separator-dashed border-warning opacity-25 mb-5'></div>

                <div className='mb-9 text-gray-900'>
                  <strong>{intl.formatMessage({id: 'MODAL.WARNING.DESC1'})}</strong>
                </div>
                {desc ? (
                  <div className='fv-row mb-7'>
                    {/* begin::Label */}
                    <label className='required fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'FORM.LABEL.DESCRIPTION'})}
                    </label>
                    {/* end::Label */}

                    <textarea
                      rows={3}
                      placeholder={''}
                      className={'form-control form-control-solid  mb-3 mb-lg-0'}
                      onChange={(event) => {
                        setText(event.target.value)
                      }}
                    />

                    {/* end::Input */}
                  </div>
                ) : null}

                <div className='d-flex flex-center flex-wrap'>
                  <button
                    className='btn btn-outline btn-outline-danger btn-active-warning m-2'
                    onClick={onCloseModal}
                  >
                    {intl.formatMessage({id: 'FORM.BUTTON.CANCEL'})}
                  </button>
                  <button
                    className='btn btn-success m-2'
                    onClick={() => {
                      onContinue(text)
                    }}
                  >
                    {intl.formatMessage({id: 'FORM.BUTTON.YES_CONTINUE'})}
                  </button>
                </div>
              </div>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className={clsx('modal-backdrop fade', {show: fade})} />

      {/* end::Modal Backdrop */}
    </>
  )
}

export default WarningModal
