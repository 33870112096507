//React
import {FC, useState, useRef, useEffect} from 'react'

//CLSX - Dynamic CSS Class Creator
import clsx from 'clsx'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

//lodash
import _ from 'lodash'

import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'

//Redux - Dispatch - Actions - State
import {useDispatch, useSelector} from 'react-redux'
import {selectCurrentUser} from '../../redux/slice/authSlice'
import {useChatStaffQuery, useAddChatStaffMutation} from '../../api/endpoints/staff'
import {useChatCustormerQuery, useAddChatCustormerMutation} from '../../api/endpoints/customer'

//AvatarCell
import AvatarCell from '../table/commonCells/AvatarCell'

//Model
import {User} from '../../models'

//Date Fcn
import {dateFormat} from '../../i18n/Metronici18n'

interface UserWithUnReadMessages extends User {
  unReadMessages: number
}
type Props = {
  isDrawer?: boolean
  selectedUser: UserWithUnReadMessages
  userType: string
}

const ChatInner: FC<Props> = ({isDrawer = false, selectedUser, userType}) => {
  const intl = useIntl()
  const currentUser = useSelector(selectCurrentUser)
  const [message, setMessage] = useState<string>('')
  const getChatMessage = userType == 'staff' ? useChatStaffQuery : useChatCustormerQuery
  const [addChatStaffMessage, addChatStaffStatus] = useAddChatStaffMutation()
  const [addChatCustomerMessage, addChatCustomerStatus] = useAddChatCustormerMutation()
  const messageEndRef = useRef<HTMLDivElement>(null)
  const [chatMessages, setChatMessages] = useState<any[]>([])
  const addChatMessage = userType === 'staff' ? addChatStaffMessage : addChatCustomerMessage
  const {
    data: dataQuery1,
    isLoading: isLoadingQuery1,
    isFetching: isFetchingQuery1,
  } = getChatMessage(
    {
      endpointData: {
        id: selectedUser?.id,
      },
      toastSuccessMessageStatus: false,
      toastErrorMessageStatus: true,
    },
    {
      pollingInterval: 1000,
    }
  )

  useEffect(() => {
    if (dataQuery1?.data != undefined && isLoadingQuery1 == false && isFetchingQuery1 == false) {
      if ([...chatMessages].length !== [...dataQuery1?.data].length) {
        setChatMessages([...dataQuery1?.data])
        setTimeout(() => {
          if ([...chatMessages].length < 1) {
            messageEndRef.current?.scrollIntoView({behavior: 'auto'})
          } else {
            messageEndRef.current?.scrollIntoView({behavior: 'smooth'})
          }
        }, 500)
      }
    }
  }, [dataQuery1, isFetchingQuery1, isLoadingQuery1, chatMessages])

  const sendMessage = async () => {
    const formData = new FormData()
    formData.append('content', message)
    try {
      await addChatMessage({
        endpointData: {
          id: selectedUser?.id,
          formData: formData,
        },
        toastSuccessMessageStatus: false,
        toastErrorMessageStatus: true,
      }).unwrap()
      setMessage('')
    } catch (error) {
      console.log(error)
    }
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  return (
    <div className=' h-100 d-flex flex-column'>
      <div className='card-header d-flex flex-row pe-5'>
        <div className='card-title'>
          <div className='d-flex justify-content-center flex-column me-3'>
            <span className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mt-2 mb-2 lh-1'>
              {`${selectedUser?.first_name} ${selectedUser?.last_name}`}
            </span>

            
          </div>
        </div>

        
      </div>
      <div className={'card-body flex-grow-1 overflow-auto'}>
        {chatMessages.map((message, index) => {
          const state = message.sender !== currentUser?.id ? 'info' : 'primary'

          const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
            message.sender !== currentUser?.id ? 'start' : 'end'
          } mb-10`
          return (
            <div
              key={`message${index}`}
              className={clsx('d-flex', contentClass, 'mb-10', {'d-none': message.template})}
            >
              <div
                className={clsx(
                  'd-flex flex-column align-items',
                  `align-items-${message.sender !== currentUser?.id ? 'start' : 'end'}`
                )}
              >
                <div className='d-flex align-items-center mb-2'>
                  {message.sender !== currentUser?.id ? (
                    <>
                      <div className='symbol  symbol-35px symbol-circle '>
                        <AvatarCell user={selectedUser as User} />
                      </div>
                      <div className='ms-3'>
                        <a
                          href='#'
                          className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                        >
                          {`${selectedUser?.first_name} ${selectedUser?.last_name}`}
                        </a>
                        <span className='text-muted fs-7 mb-1'>
                          {dateFormat(new Date(message.timestamp), 'dd MMM yy hh:mm')}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='me-3'>
                        <span className='text-muted fs-7 mb-1'>
                          {dateFormat(new Date(message.timestamp), 'dd MMM yy hh:mm')}
                        </span>
                        <a
                          href='#'
                          className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                        >
                          {intl.formatMessage({id: 'FORM.LABEL.YOU'})}
                        </a>
                      </div>
                      <div className='symbol  symbol-35px symbol-circle '>
                        <AvatarCell user={currentUser as User} />
                      </div>
                    </>
                  )}
                </div>

                <div
                  className={clsx(
                    'p-5 rounded',
                    `bg-light-${state}`,
                    'text-dark fw-bold mw-lg-400px',
                    `text-${message.sender !== currentUser?.id ? 'start' : 'end'}`
                  )}
                  data-kt-element='message-text'
                  dangerouslySetInnerHTML={{__html: message.content}}
                ></div>
              </div>
            </div>
          )
        })}

        <div ref={messageEndRef} />
      </div>

      <div
        className='card-footer pt-4 pb-4'
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        <textarea
          className='form-control form-control-flush mb-1'
          rows={1}
          data-kt-element='input'
          placeholder={intl.formatMessage({id: 'FORM.PLACEHOLDER.ENTER_MESSAGE'})}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
        ></textarea>

        <div className='d-flex flex-stack'>
          <div className='d-flex align-items-center me-2' />
          <button
            className='btn btn-primary'
            type='button'
            data-kt-element='send'
            onClick={sendMessage}
          >
            {intl.formatMessage({id: 'FORM.BUTTON.SUBMIT'})}
          </button>
        </div>
      </div>
    </div>
  )
}

export {ChatInner}
