/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import clsx from 'clsx'
import {Dispatch, FC, SetStateAction} from 'react'
import {KTIcon} from '../../../helpers'
import {Link, useLocation} from 'react-router-dom'

//Redux - Dispatch - Actions - State
import {useDispatch, useSelector} from 'react-redux'
import {setAuthState, selectCurrentUser, selectToken} from '../../../../app/redux/slice/authSlice'


//getLocaleConfig
import {dateFormat} from '../../../../app/i18n/Metronici18n'

// const tabs: ReadonlyArray<{link: string; icon: string; tooltip: string}> = [
//   {
//     link: 'projects',
//     icon: 'element-11',
//     tooltip: 'Projects',
//   },
//   {
//     link: 'menu',
//     icon: 'briefcase',
//     tooltip: 'Menu',
//   },
//   {
//     link: 'subscription',
//     icon: 'chart-simple',
//     tooltip: 'Subscription',
//   },
//   {
//     link: 'tasks',
//     icon: 'shield-tick',
//     tooltip: 'Tasks',
//   },
//   {
//     link: 'notifications',
//     icon: 'abstract-26',
//     tooltip: 'Notifications',
//   },
//   {
//     link: 'authors',
//     icon: 'add-files',
//     tooltip: 'Authors',
//   },
// ]
const tabs: ReadonlyArray<{link: string; icon: string; tooltip: string}> = [
  // {
  //   link: 'tab1',
  //   icon: 'element-11',
  //   tooltip: 'tab1',
  // },
  // {
  //   link: 'menu',
  //   icon: 'briefcase',
  //   tooltip: 'Menu',
  // },
  // {
  //   link: 'subscription',
  //   icon: 'chart-simple',
  //   tooltip: 'Subscription',
  // },
  // {
  //   link: 'tasks',
  //   icon: 'shield-tick',
  //   tooltip: 'Tasks',
  // },
  // {
  //   link: 'notifications',
  //   icon: 'abstract-26',
  //   tooltip: 'Notifications',
  // },
  // {
  //   link: 'authors',
  //   icon: 'add-files',
  //   tooltip: 'Authors',
  // },
]

type Props = {
  link: string
  setLink: Dispatch<SetStateAction<string>>
}

const AsideTabs: FC<Props> = ({link, setLink}) => {
  const location = useLocation()

  const currentUser = useSelector(selectCurrentUser)
  const is_customer = currentUser?.is_customer

  return (
    <div
      className='hover-scroll-y mb-10'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-height='auto'
      data-kt-scroll-wrappers='#kt_aside_nav'
      data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
      data-kt-scroll-offset='0px'
    >
      {/* begin::Nav */}
      <ul className='nav flex-column' id='kt_aside_nav_tabs'>
        {/* begin::Nav item */}
        {tabs.map((t) => (
          <li key={t.link}>
            {/* begin::Nav link */}
            <a
              className={clsx(
                'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light'
                // {active: t.link === link}
              )}
              onClick={() => setLink(t.link)}
            >
              <KTIcon iconName={t.icon} className='fs-2x' />
            </a>
            {/* end::Nav link */}
          </li>
        ))}

        {is_customer == true ? (
          <>
            <li>
              <Link
                className={clsx(
                  'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light mb-4',
                  {active: location.pathname === `/dashboard`}
                )}
                to={'/dashboard'}
              >
                <KTIcon iconName={'element-11'} className='fs-2x' />
              </Link>
            </li>
            <li>
              <Link
                className={clsx(
                  'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light mb-4',
                  {
                    active: location.pathname.includes(
                      `/customer-management/customer/${currentUser?.id}/memberships`
                    ),
                  }
                )}
                to={`/customer-management/customer/${currentUser?.id}/memberships`}
              >
                <KTIcon iconName={'gift'} className='fs-2x' />
              </Link>
            </li>
            <li>
              <Link
                className={clsx(
                  'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light mb-4',
                  {
                    active: location.pathname.includes(
                      `/customer-management/customer/${currentUser?.id}/calendar`
                    ),
                  }
                )}
                to={`/customer-management/customer/${currentUser?.id}/calendar`}
              >
                <KTIcon iconName={'calendar-tick'} className='fs-2x' />
              </Link>
            </li>
            <li>
              <Link
                className={clsx(
                  'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light mb-4',
                  {
                    active: location.pathname.includes(
                      `/customer-management/customer/${currentUser?.id}/extra`
                    ),
                  }
                )}
                to={`/customer-management/customer/${currentUser?.id}/extra`}
              >
                <KTIcon iconName={'tag'} className='fs-2x' />
              </Link>
            </li>
            <li>
              <Link
                className={clsx(
                  'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light mb-4',
                  {
                    active: location.pathname.includes(
                      `/customer-management/customer/${currentUser?.id}/health-information`
                    ),
                  }
                )}
                to={`/customer-management/customer/${currentUser?.id}/health-information`}
              >
                <KTIcon iconName={'pulse'} className='fs-2x' />
              </Link>
            </li>
            <li>
              <Link
                className={clsx(
                  'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light mb-4',
                  {
                    active: location.pathname.includes(
                      `/customer-management/customer/${currentUser?.id}/nutrition`
                    ),
                  }
                )}
                to={`/customer-management/customer/${currentUser?.id}/nutrition`}
              >
                <KTIcon iconName={'abstract-46'} className='fs-2x' />
              </Link>
            </li>
            <li>
              <Link
                className={clsx(
                  'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light mb-4',
                  {
                    active: location.pathname.includes(
                      `/customer-management/customer/${currentUser?.id}/package-transactions`
                    ),
                  }
                )}
                to={`/customer-management/customer/${currentUser?.id}/package-transactions`}
              >
                <KTIcon iconName={'minus-circle'} className='fs-2x' />
              </Link>
            </li>
            <li>
              <Link
                className={clsx(
                  'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light mb-4',
                  {
                    active: location.pathname.includes(
                      `/customer-management/customer/${currentUser?.id}/entering_exiting`
                    ),
                  }
                )}
                to={`/customer-management/customer/${currentUser?.id}/entering_exiting`}
              >
                <KTIcon iconName={'pointers'} className='fs-2x' />
              </Link>
            </li>
            <li>
              <Link
                className={clsx(
                  'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light mb-4',
                  {
                    active: location.pathname.includes(
                      `/customer-management/customer/${currentUser?.id}/discover`
                    ),
                  }
                )}
                to={`/customer-management/customer/${currentUser?.id}/discover`}
              >
                <KTIcon iconName={'eye'} className='fs-2x' />
              </Link>
            </li>
          </>
        ) : (
          <>
            <li>
              <Link
                className={clsx(
                  'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light mb-4',
                  {active: location.pathname === `/dashboard`}
                )}
                to={'/dashboard'}
              >
                <KTIcon iconName={'element-11'} className='fs-2x' />
              </Link>
            </li>
            <li>
              <Link
                className={clsx(
                  'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light mb-4',
                  {active: location.pathname.includes('/calendar-and-reservation/')}
                )}
                to={'/calendar-and-reservation/calendar'}
              >
                <KTIcon iconName={'calendar'} className='fs-2x' />
              </Link>
            </li>
            <li>
              <Link
                className={clsx(
                  'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light',
                  {active: location.pathname.includes('/customer-management/')}
                )}
                to={'/customer-management'}
              >
                <KTIcon iconName={'profile-circle'} className='fs-2x' />
              </Link>
            </li>
            <li>
              <Link
                className={clsx(
                  'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light mb-4',
                  {active: location.pathname.includes('/staff-management/')}
                )}
                to={'/staff-management'}
              >
                <KTIcon iconName={'profile-user'} className='fs-2x' />
              </Link>
            </li>
            <li>
              <Link
                className={clsx(
                  'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light mb-4',
                  {active: location.pathname === `/financial-management`}
                )}
                to={'/financial-management'}
              >
                <KTIcon iconName={'dollar'} className='fs-2x' />
              </Link>
            </li>
            <li>
              <Link
                className={clsx(
                  'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light mb-4',
                  {active: location.pathname.includes('/reports/')}
                )}
                to={'/reports'}
              >
                <KTIcon iconName={'chart-pie-3'} className='fs-2x' />
              </Link>
            </li>
          </>
        )}
      </ul>
      {/* end::Tabs */}
    </div>
  )
}

export {AsideTabs}
