//React
import React, {FC, useEffect, useState} from 'react'

//CLSX - Dynamic CSS Class Creator
import clsx from 'clsx'

//React Router Dom
import {useNavigate, Link} from 'react-router-dom'

// React Hook Form - Yup Validation
import {useForm, Controller} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'

//Helpers- Create Public File Path
import {toAbsoluteUrl} from '../../helpers/AssetHelpers'

//Redux ToolKit API
import {useSingupMutation, useCheckUsernameMutation} from '../../api/endpoints/auth'

//Redux - Dispatch - Actions - State
import {useDispatch, useSelector} from 'react-redux'
import {selectInitialData} from '../../redux/slice/initialDataSlice'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

const SingUp: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const initialdata = useSelector(selectInitialData)
  const [request, {isLoading, isError, data}] = useSingupMutation()
  const [requestCheckUsername] = useCheckUsernameMutation()

  const schema = Yup.object().shape({
    first_name: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    last_name: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    username: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    email: Yup.string()
      .email(intl.formatMessage({id: 'FORM.VALIDATION.EMAIL'}))
      .required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    gender: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    birth_of_date: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    password1: Yup.string()
      .min(3, intl.formatMessage({id: 'FORM.VALIDATION.MIN_LENGTH_FIELD'}, {name: '3'}))
      .max(50, intl.formatMessage({id: 'FORM.VALIDATION.MAX_LENGTH_FIELD'}, {name: '50'}))
      .required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    password2: Yup.string()
      .min(3, intl.formatMessage({id: 'FORM.VALIDATION.MIN_LENGTH_FIELD'}, {name: '3'}))
      .max(50, intl.formatMessage({id: 'FORM.VALIDATION.MAX_LENGTH_FIELD'}, {name: '50'}))
      .oneOf([Yup.ref('password1')], intl.formatMessage({id: 'FORM.VALIDATION.PASSWORD_CONFIRM'}))
      .required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
  })

  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    control,
    formState: {errors, touchedFields, isSubmitting, isValid},
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      first_name: '',
      last_name: '',
      username: '',
      email: '',
      gender: '',
      password1: '',
      password2: '',
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = (formData: any) => {
    const sendFormdata = new FormData()

    Object.keys(formData).forEach(function (key) {
      sendFormdata.append(key, formData[key])
    })

    request({
      endpointData: sendFormdata,
      toastSuccessMessageStatus: true,
      toastErrorMessageStatus: true,
    })
  }

  useEffect(() => {
    if (!isLoading && !isError && data !== undefined) {
      navigate('/auth/singin')
    }
  }, [isLoading, isError, data])

  const watchUserNameField = watch('username', '')

  const controlUserName = async () => {
    const sendFormdata = new FormData()
    sendFormdata.append('username', watchUserNameField)
    try {
      const res = await requestCheckUsername({
        endpointData: sendFormdata,
        toastSuccessMessageStatus: false,
        toastErrorMessageStatus: false,
      }).unwrap()
      clearErrors('username')
    } catch (error) {
      const typedError = error as {data?: {message?: string; data?: string}}

      setError(
        'username',
        {type: 'custom', message: `${typedError?.data?.message} - ${typedError?.data?.data}`},
        {shouldFocus: true}
      )
    }
  }

  return (
    <form
      className='form w-100'
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>
          {intl.formatMessage({id: 'SINGUP_PAGE.TITLE'})}
        </h1>
        <div className='text-gray-500 fw-semibold fs-6'>
          {intl.formatMessage({id: 'SINGUP_PAGE.LOGIN_WITH_SOCIAL_MEDIA'})}
        </div>
      </div>
      {/* begin::Heading */}

      {/* begin::Login options */}
      <div className='row g-3 mb-9'>
        {/* begin::Col */}
        <div className='col-md-6'>
          {/* begin::Google link */}
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Google Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            {intl.formatMessage({id: 'SINGUP_PAGE.SINGIN_WITH_GOOGLE'})}
          </a>
          {/* end::Google link */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-md-6'>
          {/* begin::Google link */}
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Apple Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Apple Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            {intl.formatMessage({id: 'SINGUP_PAGE.SINGIN_WITH_APPLE'})}
          </a>
          {/* end::Google link */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Login options */}

      {/* begin::Separator */}
      <div className='separator separator-content my-14'>
        <span className='w-250px text-gray-500 fw-semibold fs-7'>
          {intl.formatMessage({id: 'SINGUP_PAGE.OR_SINGUP_CUSTOM'})}
        </span>
      </div>
      {/* end::Separator */}

      {/* begin::Form group */}

      <div className='row'>
        <div className='col fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'FORM.LABEL.USERNAME'})}
          </label>
          <Controller
            name={'username'}
            control={control}
            defaultValue=''
            render={({field}) => (
              <input
                type='text'
                {...field}
                autoComplete='off'
                placeholder={intl.formatMessage({id: 'FORM.PLACEHOLDER.USERNAME'})}
                className={'form-control bg-transparent'}
                onBlur={() => {
                  field.onBlur()
                  controlUserName()
                }}
              />
            )}
          />

          {touchedFields?.username && errors?.username && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.username?.message}</span>
              </div>
            </div>
          )}
        </div>

        <div className='col fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'FORM.LABEL.FIRST_NAME'})}
          </label>
          <input
            {...register('first_name')}
            type='text'
            placeholder={intl.formatMessage({id: 'FORM.PLACEHOLDER.FIRST_NAME'})}
            autoComplete='off'
            className={clsx('form-control bg-transparent')}
          />
          {touchedFields?.first_name && errors?.first_name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.first_name?.message}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'FORM.LABEL.LAST_NAME'})}
          </label>
          <input
            {...register('last_name')}
            type='text'
            placeholder={intl.formatMessage({id: 'FORM.PLACEHOLDER.LAST_NAME'})}
            autoComplete='off'
            className={clsx('form-control bg-transparent')}
          />
          {touchedFields?.last_name && errors?.last_name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.last_name?.message}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'FORM.LABEL.EMAIL'})}
          </label>
          <input
            {...register('email')}
            type='email'
            placeholder={intl.formatMessage({id: 'FORM.PLACEHOLDER.EMAIL'})}
            autoComplete='off'
            className={clsx('form-control bg-transparent')}
          />
          {touchedFields?.email && errors?.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.email?.message}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'FORM.LABEL.GENDER'})}
          </label>
          <select
            {...register('gender')}
            autoComplete='off'
            className={clsx('form-control form-select  bg-transparent')}
          >
            <option value={''}>{intl.formatMessage({id: 'FORM.PLACEHOLDER.GENDER'})}</option>
            {initialdata?.gender.map((value: any, index: number) => (
              <option key={index} value={value?.id}>
                {value?.name}
              </option>
            ))}
          </select>
          {touchedFields?.gender && errors?.gender && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.gender?.message}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'FORM.LABEL.BIRTH_OF_DATE'})}
          </label>
          <input
            {...register('birth_of_date')}
            type='date'
            placeholder={intl.formatMessage({id: 'FORM.PLACEHOLDER.BIRTH_OF_DATE'})}
            autoComplete='off'
            className={clsx('form-control bg-transparent')}
          />
          {touchedFields?.birth_of_date && errors?.birth_of_date && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.birth_of_date?.message}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6 '>
            {intl.formatMessage({id: 'FORM.LABEL.PASSWORD'})}
          </label>
          <input
            {...register('password1')}
            type='password'
            placeholder={intl.formatMessage({id: 'FORM.PLACEHOLDER.PASSWORD'})}
            autoComplete='off'
            className={clsx('form-control bg-transparent')}
          />
          {touchedFields?.password1 && errors?.password1 && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.password1?.message}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6 '>
            {intl.formatMessage({id: 'FORM.LABEL.PASSWORD_CONFIRM'})}
          </label>
          <input
            {...register('password2')}
            type='password'
            placeholder={intl.formatMessage({id: 'FORM.PLACEHOLDER.PASSWORD_CONFIRM'})}
            autoComplete='off'
            className={clsx('form-control bg-transparent')}
          />
          {touchedFields?.password2 && errors?.password2 && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.password2?.message}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={isSubmitting || !isValid}
        >
          {!isLoading && (
            <span className='indicator-label'>
              {intl.formatMessage({id: 'FORM.BUTTON.SINGUP'})}
            </span>
          )}
          {isLoading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'MESSAGE.PLEASE_WAITING'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        {intl.formatMessage({id: 'SINGUP_PAGE.HAVE_ACCOUNT'})}{' '}
        <Link to='/auth/singin' className='link-primary'>
          {intl.formatMessage({id: 'SINGUP_PAGE.SINGUP'})}
        </Link>
      </div>
    </form>
  )
}

export default SingUp
