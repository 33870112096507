export default function ({baseUrl, endpointData}) {
  let url = baseUrl
  if (endpointData?.limit == -1) {
    url = `${url}?page_size=-1`
  } else {
    url = `${url}?page_size=${endpointData?.limit === undefined ? '-1':endpointData?.limit}`
    Object.keys(endpointData).forEach(function (key) {
      if (
        key != 'limit' &&
        endpointData[key] != '' &&
        endpointData[key] != undefined &&
        endpointData[key] != null
      )
        url = `${url}&${key}=${endpointData[key]}`
    })
  }
  return url
}
