// createApi
import api from '..'

// paths
import {CUSTOMER, STOPPING_REQUESTS} from '../paths'

// helpers - serializeQueryArgs
import serializeQueryArgs from '../helpers/serializeQueryArgs'
import filterQueryUrlCreater from '../helpers/filterQueryUrlCreater'

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    custormers: builder.query({
      query: (credentials) => ({
        url: filterQueryUrlCreater({baseUrl: CUSTOMER, endpointData: credentials.endpointData}),
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        const result = response?.data?.data
        return result
          ? [...result.map(({id}) => ({type: 'CUSTOMERS', id})), {type: 'CUSTOMERS', id: 'LIST'}]
          : [{type: 'CUSTOMERS', id: 'LIST'}]
      },
    }),
    createCustormer: builder.mutation({
      query: (credentials) => ({
        url: CUSTOMER + 'create-customer',
        method: 'POST',
        body: credentials.endpointData,
      }),
      serializeQueryArgs,
      invalidatesTags: [{type: 'CUSTOMERS', id: 'LIST'}],
    }),
    generalCustormer: builder.query({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/general/`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'GENERAL'},
        ]
      },
    }),
    profilCustormer: builder.query({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'PROFIL'},
        ]
      },
    }),
    deleteCustormer: builder.mutation({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/`,
        method: 'DELETE',
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [{type: 'CUSTOMERS', id: 'LIST'}]
      },
    }),
    updateProfilCustormer: builder.mutation({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/`,
        method: 'PUT',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'PROFIL'},
          {type: 'CUSTOMERS', id: arg.endpointData.id},
        ]
      },
    }),
    addAvatarCustormer: builder.mutation({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/avatar/`,
        method: 'POST',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'PROFIL'},
          {type: 'CUSTOMERS', id: arg.endpointData.id},
        ]
      },
    }),
    deleteAvatarCustormer: builder.mutation({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/avatar/`,
        method: 'DELETE',
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'PROFIL'},
          {type: 'CUSTOMERS', id: arg.endpointData.id},
        ]
      },
    }),
    calendarCustormer: builder.query({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/calendar/?page_size=${credentials.endpointData.limit}&search=${credentials.endpointData.search}&start_date=${credentials.endpointData.start_date}&end_date=${credentials.endpointData.end_date}&lecture=${credentials.endpointData.lecture}&room=${credentials.endpointData.room}&personel=${credentials.endpointData.personel}&appointmentstatus=${credentials.endpointData.appointmentstatus}`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'CALENDAR'},
        ]
      },
    }),
    updateCalendarCustormer: builder.mutation({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/calendar/${credentials.endpointData.calendarId}/`,
        method: 'PUT',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'CALENDAR'},
          {type: 'CUSTOMERS', id: arg.endpointData.id},
          {type: 'CALENDAR', id: 'LIST'},
        ]
      },
    }),
    appointmentCustormers: builder.query({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/appointments/?page=${credentials.endpointData.page}&page_size=${credentials.endpointData.limit}`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'CALENDAR'},
          {type: 'CUSTOMERS', id: arg.endpointData.id},
        ]
      },
    }),
    createAppointmentCustormer: builder.mutation({
      query: (credentials) => ({
        url: `create-appointment/`,
        method: 'POST',
        body: credentials.endpointData,
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'CALENDAR'},
          {type: 'CUSTOMERS', id: arg.endpointData.id},
        ]
      },
    }),
    deleteAppointmentCustormer: builder.mutation({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/appointments/${credentials.endpointData.appointmentId}/`,
        method: 'DELETE',
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'CALENDAR'},
          {type: 'CUSTOMERS', id: arg.endpointData.id},
        ]
      },
    }),
    updateAppointmentCustormer: builder.mutation({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/appointments/${credentials.endpointData.appointmentId}/`,
        method: 'PUT',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'CALENDAR'},
          {type: 'CUSTOMERS', id: arg.endpointData.id},
        ]
      },
    }),
    addScheduleLecture: builder.mutation({
      query: (credentials) => ({
        url: `/appointment/${credentials.endpointData.appointmentId}/private-lecture`,
        method: 'POST',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'CALENDAR'},
          {type: 'CUSTOMERS', id: arg.endpointData.id},
        ]
      },
    }),
    bodyAnalysisCustormer: builder.query({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/body-analysis/`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'BODYANALYSIS'},
        ]
      },
    }),
    createBodyAnalysisCustormer: builder.mutation({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/create-body-analysis/`,
        method: 'POST',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: [{type: 'CUSTOMER', id: 'BODYANALYSIS'}],
    }),
    deleteBodyAnalysisCustormer: builder.mutation({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/body-analysis/${credentials.endpointData.bodyAnalysisId}`,
        method: 'DELETE',
      }),
      serializeQueryArgs,
      invalidatesTags: [{type: 'CUSTOMER', id: 'BODYANALYSIS'}],
    }),
    healthInformationCustormer: builder.query({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/healty-info/`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'HEALTHINFORMATION'},
        ]
      },
    }),
    updateHealthInformationCustormer: builder.mutation({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/healty-info/`,
        method: 'PUT',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: [{type: 'CUSTOMERS', id: 'HEALTHINFORMATION'}],
    }),
    logCustormer: builder.query({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/logs/`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'LOG'},
        ]
      },
    }),
    packageTransactionsCustormer: builder.query({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/appointment-activities/`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'PACKAGE_TRANSACTIONS'},
        ]
      },
    }),
    packageIndependentTransactionsCustormer: builder.query({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/independent-calendar-activities/`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'PACKAGE_INDEPENDENT_TRANSACTIONS'},
        ]
      },
    }),
    extraCustormer: builder.query({
      query: (credentials) => ({
        url: filterQueryUrlCreater({
          baseUrl: `${CUSTOMER}${credentials.endpointData.id}/account-statement`,
          endpointData: credentials.endpointData,
        }),
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'EXTRA'},
        ]
      },
    }),
    referenceCustormer: builder.query({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/guests/`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'REFERENCE'},
        ]
      },
    }),
    preferenceCustormer: builder.query({
      query: credentials => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/preferences/`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'PREFERENCE'},
        ];
      },
    }),
    updatePreferenceCustormer: builder.mutation({
      query: credentials => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/preferences/`,
        method: 'PUT',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'PREFERENCE'},
          {type: 'CUSTOMERS', id: arg.endpointData.id},
        ];
      },
    }),
    authorisationCustormer: builder.query({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/authorisation/`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'AUTHORISATION'},
        ]
      },
    }),
    updateAuthorisationCustormer: builder.mutation({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/authorisation/`,
        method: 'PUT',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: [{type: 'CUSTOMER', id: 'AUTHORISATION'}],
    }),
    chatCustormer: builder.query({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/chat`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [{type: 'CHAT', id: `CUSTOMER_${arg.endpointData.id}`}]
      },
    }),
    addChatCustormer: builder.mutation({
      query: (credentials) => ({
        url: `${CUSTOMER}${credentials.endpointData.id}/chat`,
        method: 'POST',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [{type: 'CHAT', id: `CUSTOMER_${arg.endpointData.id}`}]
      },
    }),
    createStoppingRequests: builder.mutation({
      query: (credentials) => ({
        url: `${STOPPING_REQUESTS}create`,
        method: 'POST',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: [{type: 'CUSTOMER', id: 'STOPPING_REQUESTS'}],
    }),
    cancelStopped: builder.mutation({
      query: (credentials) => ({
        url: `/update_appointment/${credentials.endpointData.is_stopped_id}/`,
        method: 'PUT',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [
          {type: 'CUSTOMER', id: arg.endpointData.id},
          {type: 'CUSTOMER', id: 'CALENDAR'},
          {type: 'CUSTOMERS', id: arg.endpointData.id},
        ]
      },
    }),
  }),
})

export const {
  useCustormersQuery,
  useCreateCustormerMutation,
  useGeneralCustormerQuery,
  useProfilCustormerQuery,
  useDeleteCustormerMutation,
  useUpdateProfilCustormerMutation,
  useAddAvatarCustormerMutation,
  useDeleteAvatarCustormerMutation,
  useCalendarCustormerQuery,
  useUpdateCalendarCustormerMutation,
  useAppointmentCustormersQuery,
  useAddScheduleLectureMutation,
  useCreateAppointmentCustormerMutation,
  useUpdateAppointmentCustormerMutation,
  useDeleteAppointmentCustormerMutation,
  useBodyAnalysisCustormerQuery,
  useCreateBodyAnalysisCustormerMutation,
  useDeleteBodyAnalysisCustormerMutation,
  useHealthInformationCustormerQuery,
  useUpdateHealthInformationCustormerMutation,
  useLogCustormerQuery,
  usePackageTransactionsCustormerQuery,
  usePackageIndependentTransactionsCustormerQuery,
  useExtraCustormerQuery,
  useReferenceCustormerQuery,
  usePreferenceCustormerQuery,
  useUpdatePreferenceCustormerMutation,
  useAuthorisationCustormerQuery,
  useUpdateAuthorisationCustormerMutation,
  useChatCustormerQuery,
  useAddChatCustormerMutation,
  useCreateStoppingRequestsMutation,
  useCancelStoppedMutation
} = extendedApi
