export const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL

export const HOST_NAME = 'https://baselnext.com.tr/api'


export const BASE_URL = HOST_NAME

export const INITIALDATA = '/constants'


export const AUTH_SINGIN = '/login/'

export const AUTH_SINGUP = '/register/'

export const FORGOT_PASSWORD_PATH = '/forgot_password/'

export const RESET_PASSWORD_PATH = '/reset_password/'

export const CHECK_USERNAME_PATH = '/check-username/'

export const UPDATE_PASSWORD = '/change-password'

export const CUSTOMER = '/customer/'

export const PACKAGE = '/package/'

export const LECTURE = '/explore/'

export const FACILITY = '/facility/'

export const ROOM = '/room/'

export const GATE = '/gates/'

export const POS = '/pos/'

export const COMPANY = '/company/'

export const CUPON = '/cupons'

export const CONTRACT = '/contracts/'

export const CALENDAR = '/calendar/'

export const STAFF = '/staff/'

export const SUMMARY = '/reports'

export const ACCOUNT = '/profile/'

export const MESSAGES = '/messages/'

export const STOPPING_REQUESTS = '/stopping-requests/'

export const INVITE_FACILITY = '/invitation/'



export const FIRM_PATH = '/firm'
export const SINGIN_PATH = '/auth/private/singin'
export const SINGUP_PATH = '/auth/singup'




export const AUTH_SINGUP_CODE = 'auth/singup_code'

export const AUTH_FORGOT_PASSWORD = 'auth/forgotpassword'

export const AUTH_FORGOT_PASSWPRD_CODE = 'auth/forgotpassword_code'



export const ACCOUNT_AVATAR = 'account/avatar'

export const ACCOUNT_UPDATE_PASSWORD = 'account/updatepassword'

export const ACCOUNT_UPDATE_SMSPERMISSIN = 'account/smspermission'

export const ACCOUNT_LOG_OUT = 'account/logout'

export const POST = 'post'

export const POSTS = 'posts'

export const BLOG = 'blog'

export const BLOGS = 'blogs'

export const ADVERTISINGS = 'advertisings'

export const POSTCATEGORIES = 'postcategories'

export const CAMPAIGNS = 'campaigns'



export const NOTIFICATION = 'notification'

export const SMS = 'sms'

export const NOTIFICATION_READ = 'notification/read'

export const SYSTEM_USERS = 'systemusers'

export const SYSTEM_USER = 'systemuser'

export const LOGS = 'logs'

export const SETTINGS = 'settings'

export const USERS = 'users'

export const USER = 'user'



export const SUPPORT_REQUESTS = 'supportrequests'




