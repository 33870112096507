/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import clsx from 'clsx'
import {useThemeMode} from '../../../../_metronic/partials'
import {dateFormat} from '../../../i18n/Metronici18n'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

type Props = {
  roomUsageRate: object | undefined
  className: string
  chartColor: string
  chartHeight: string
}

const RoomUsageRate: React.FC<Props> = ({className, chartColor, chartHeight, roomUsageRate}) => {
  const intl = useIntl()
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const [chartData, setChartData] = useState({
    rooms: [] as string[],
  })
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartHeight, chartData, roomUsageRate)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, chartData])

  useEffect(() => {
    if (roomUsageRate != undefined) {
      let rooms: string[] = []

      Object.keys(roomUsageRate).forEach(function (key) {
        rooms.push(key as string)
      })

      setChartData({
        rooms,
      })
    }
  }, [roomUsageRate])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header  */}
      <div className={`card-header border-0 bg-${chartColor} py-5`}>
        <h3 className='card-title fw-bold text-white'> {intl.formatMessage({id: 'DASHBOARD.ROOM_USAGE_RATE.TITLE'})}</h3>
      </div>
      {/* end::Header  */}

      {/* begin::Body  */}
      <div className='card-body p-0'>
        {/* begin::Chart  */}
        <div
          ref={chartRef}
          className={`mixed-widget-12-chart card-rounded-bottom bg-${chartColor}`}
          style={{height: '170px'}}
        ></div>
        {/* end::Chart  */}

        {/* begin::Stats  */}

        {/* end::Stats  */}
      </div>
      {/* end::Body  */}
    </div>
  )
}

const chartOptions = (chartHeight: string, chartData, roomUsageRate): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-100')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  return {
    series: [
      ...chartData.rooms.map((val) => ({
        name: val,
        data: [...roomUsageRate[val].map((value) => value.weight)],
      })),
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['transparent'],
    },
    xaxis: {
      categories:
        chartData?.rooms[0] == undefined
          ? []
          : [...roomUsageRate[chartData.rooms[0]].map((value) => dateFormat(new Date(value.date), 'dd MMM yy'))],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      min: 0,
      max: 0.1,
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      type: ['solid', 'solid'],
      opacity: [1, 1],
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },

      marker: {
        show: false,
      },
    },
    colors: ['#ffffff', '#ffffff'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        left: 20,
        right: 20,
      },
    },
  }
}

export {RoomUsageRate}
