// createApi
import api from '..'

// paths
import {
  AUTH_SINGIN,
  AUTH_SINGUP,
  SINGUP_PATH,
  FORGOT_PASSWORD_PATH,
  RESET_PASSWORD_PATH,
  AUTH_SINGOUT,
  UPDATE_PASSWORD,
  CHECK_USERNAME_PATH,
} from '../paths'

// helpers - serializeQueryArgs
import serializeQueryArgs from '../helpers/serializeQueryArgs'

import {TAGS} from '../helpers/tags'

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    singin: builder.mutation({
      query: (credentials) => ({
        url: AUTH_SINGIN,
        method: 'POST',
        body: credentials.endpointData,
      }),
      invalidatesTags: TAGS,
    }),
    singup: builder.mutation({
      query: (credentials) => ({
        url: AUTH_SINGUP,
        method: 'POST',
        body: credentials.endpointData,
      }),
      invalidatesTags: TAGS,
    }),
    forgotpassword: builder.mutation({
      query: (credentials) => ({
        url: FORGOT_PASSWORD_PATH,
        method: 'POST',
        body: credentials.endpointData,
      }),
    }),
    forgotpasswordcode: builder.mutation({
      query: (credentials) => ({
        url: FORGOT_PASSWORD_PATH + '_code',
        method: 'POST',
        body: credentials.endpointData,
      }),
    }),
    resetpassword: builder.mutation({
      query: (credentials) => ({
        url: RESET_PASSWORD_PATH,
        method: 'POST',
        body: credentials.endpointData,
      }),
    }),
    updatepassword: builder.mutation({
      query: (credentials) => ({
        url: UPDATE_PASSWORD,
        method: 'POST',
        body: credentials.endpointData,
      }),
    }),
    checkUsername: builder.mutation({
      query: (credentials) => ({
        url: CHECK_USERNAME_PATH,
        method: 'POST',
        body: credentials.endpointData,
      }),
    }),
  }),
})

export const {
  useSinginMutation,
  useSingupMutation,
  useForgotpasswordMutation,
  useForgotpasswordcodeMutation,
  useResetpasswordMutation,
  useUpdatepasswordMutation,
  useCheckUsernameMutation,
} = extendedApi
