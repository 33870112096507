///React
import React, {useEffect, useRef, useState} from 'react'

//Helpers
import {KTIcon} from '../../../../_metronic/helpers'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

//Redux - Dispatch - Actions - State
import {useDispatch, useSelector} from 'react-redux'
import {selectInitialData} from '../../../redux/slice/initialDataSlice'
import {
  useInviteFacilitysQuery,
  useUpdateInviteFacilityMutation,
} from '../../../api/endpoints/inviteFacility'
import {useStaffsQuery} from '../../../api/endpoints/staff'
import {useCustormersQuery} from '../../../api/endpoints/customer'

//Modal
import InviteModal from '../../../components/modalForms/inviteModal'
import {dateFormat} from '../../../i18n/Metronici18n'

type Props = {
  className: string
}

const InviteFacility: React.FC<Props> = ({className}) => {
  const intl = useIntl()

  const initialdata = useSelector(selectInitialData)

  const [requestMutation1] = useUpdateInviteFacilityMutation()

  const [inviteModalState, setInviteModalState] = useState({
    modalVisible: false,
    status: true,
    id: -1,
  })

  const [data, setData] = useState<any[]>([])
  const [filter, setFilter] = useState('false')

  const {data: staffData} = useStaffsQuery({
    endpointData: {
      limit: -1,
    },
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })

  const {data: customerData} = useCustormersQuery({
    endpointData: {
      limit: -1,
    },
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })

  const {
    data: dataQuery1,
    isLoading: isLoadingQuery1,
    isFetching: isFetchingQuery1,
    isError: isErrorQuery1,
    refetch: refetchQuery1,
  } = useInviteFacilitysQuery({
    endpointData: {},
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })

  useEffect(() => {
    if (
      dataQuery1?.data?.data != undefined &&
      staffData?.data?.data != undefined &&
      customerData?.data?.data != undefined &&
      isLoadingQuery1 == false &&
      isFetchingQuery1 == false
    ) {
      const updateData = dataQuery1?.data?.data.map((value) => {
        let inviteruser = null
        const findUser = customerData?.data?.data.find((user) => user?.id === value?.inviter)

        
        const findStaff = staffData?.data?.data.find((user) => user?.id === value?.inviter)

        if (findUser !== undefined) {
          inviteruser = findUser
        } else if (findStaff !== undefined) {
          inviteruser = findStaff
        }
        return {
          ...value,
          inviteruser,
        }
      })

      setData([
        ...updateData.filter((x) =>
          filter === ''
            ? true
            : filter === 'true'
            ? x?.approval_status === true
            : x?.approval_status === false
        ),
      ])
    }
  }, [dataQuery1, isFetchingQuery1, isLoadingQuery1, filter, staffData, customerData])

  

  const update = async (id, approval_status, date) => {
    const formData = new FormData()
    formData.append('approval_status', approval_status)
    formData.append('approved_date', date)
    requestMutation1({
      endpointData: {
        id,
        formData,
      },
      toastSuccessMessageStatus: false,
      toastErrorMessageStatus: true,
    })
  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {intl.formatMessage({id: 'FORM.LABEL.INVITE_FACILITY'})}
          </span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>

          <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'>
                {intl.formatMessage({id: 'FILTER.OPTIONS'})}
              </div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5'>
              <div className='mb-10'>
                <label className='form-label fw-bold'>
                  {intl.formatMessage({id: 'FORM.LABEL.STATUS'})}
                </label>

                <div>
                  <select
                    className='form-select form-select-solid'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    value={filter}
                    onChange={(event) => {
                      setFilter(event.target.value)
                    }}
                  >
                    <option value={''}>{intl.formatMessage({id: 'FORM.OPTION.ALL'})}</option>
                    <option value={'true'}>
                      {intl.formatMessage({id: 'FORM.OPTION.INVITED'})}
                    </option>
                    <option value={'false'}>
                      {intl.formatMessage({id: 'FORM.OPTION.NOT_INVITED'})}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>{intl.formatMessage({id: 'FORM.LABEL.ID'})}</th>
                <th className='min-w-150px'>
                  {intl.formatMessage({id: 'FORM.LABEL.INVITER_USER'})}
                </th>

                <th className='min-w-150px'>
                  {intl.formatMessage({id: 'FORM.LABEL.REQUEST_DATE'})}
                </th>
                <th className='min-w-150px'>
                  {intl.formatMessage({id: 'FORM.LABEL.NAME_SURNAME'})}
                </th>
                <th className='min-w-140px'>
                  {intl.formatMessage({id: 'FORM.LABEL.PHONE_NUMBER'})}
                </th>
                <th className='min-w-120px'>
                  {intl.formatMessage({id: 'FORM.LABEL.INVITE_DATE'})}
                </th>
                <th className='min-w-120px'>{intl.formatMessage({id: 'FORM.LABEL.STATUS'})}</th>

                <th className='min-w-100px text-end'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}

            <tbody>
              {data.map((value, index) => (
                <tr key={index}>
                  <td>{value?.id}</td>
                  <td>
                    {value?.inviteruser ? (
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          <img src={value?.inviteruser?.avatar} alt='' />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {`${value?.inviteruser?.first_name} ${value?.inviteruser?.last_name}`}
                          </a>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {value?.inviteruser?.username}
                          </span>
                        </div>
                      </div>
                    ) : null}
                  </td>
                  <td>{`${dateFormat(new Date(value?.requested_date), 'dd MMM yyyy')}`}</td>

                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                          {`${value?.first_name} ${value?.last_name}`}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                      {`${value?.phone_number}`}
                    </a>
                  </td>

                  <td>
                    {value?.approved_date
                      ? `${dateFormat(new Date(value?.approved_date), 'dd.MM.yyyy')}`
                      : null}
                  </td>
                  <td>
                    {value?.approval_status
                      ? intl.formatMessage({id: 'FORM.OPTION.INVITED'})
                      : intl.formatMessage({id: 'FORM.OPTION.NOT_INVITED'})}
                  </td>

                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      {value?.approval_status === true ? null : (
                        <button
                          className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
                          onClick={(event) => {
                            event.stopPropagation()
                            setInviteModalState({
                              modalVisible: true,
                              id: value.id,
                              status: true,
                            })
                          }}
                        >
                          <KTIcon iconName='user-tick' className='fs-3' />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {inviteModalState.modalVisible ? (
        <InviteModal
          modalVisible={inviteModalState.modalVisible}
          onCloseModal={() =>
            setInviteModalState({
              modalVisible: false,
              status: true,
              id: -1,
            })
          }
          onContinue={(date) => {
            update(inviteModalState.id, inviteModalState.status, date)
            setInviteModalState({
              modalVisible: false,
              status: true,
              id: -1,
            })
          }}
        />
      ) : null}
    </div>
  )
}

export {InviteFacility}
