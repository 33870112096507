import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'initialdata',
  initialState: {
    initialdata: null,
  },
  reducers: {
    setInitialData: (state, {payload}) => {
      state.initialdata = payload
    },
  },
})

export const {setInitialData} = slice.actions

export default slice.reducer

export const selectInitialData = (state) => state.initialDataReducer.initialdata
