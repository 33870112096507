/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'
import clsx from 'clsx'

//Redux - Dispatch - Actions - State
import {useDispatch, useSelector} from 'react-redux'
import {setAuthState, selectCurrentUser, selectToken} from '../../../../app/redux/slice/authSlice'
import {selectInitialData} from '../../../../app/redux/slice/initialDataSlice'

const HeaderUserMenu: FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)
  const initialdata = useSelector(selectInitialData)

  const staffRole = currentUser?.role

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
          {currentUser?.avatar ? (
            <img
              src={currentUser?.avatar}
              alt={`${currentUser?.first_name}_${currentUser?.last_name}`}
            />
          ) : (
            <div
              className={clsx(
                'symbol-label fs-3',
                `bg-light-${currentUser?.status ? 'success' : 'danger'}`,
                `text-${currentUser?.status ? 'success' : 'danger'}`
              )}
            >
              {`${currentUser?.first_name?.charAt(0).toUpperCase()}${currentUser?.last_name
                ?.charAt(0)
                .toUpperCase()}`}
            </div>
          )}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.first_name} {currentUser?.last_name}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.username}
            </a>
            <div className='fw-bolder d-flex align-items-center fs-5 flex-wrap'>
              {currentUser?.is_customer == false || initialdata?.user_roles == undefined ? null : (
                <span className='badge badge-light-success fw-bolder fs-8 me-2'>
                  {initialdata?.user_roles.filter((role: any) => role.id == currentUser?.role)[0]
                    ?.role || ''}
                </span>
              )}
              {initialdata?.roles == undefined ||
              currentUser?.role == undefined ||
              currentUser?.is_customer == true
                ? null
                : (currentUser?.role as unknown as number[]).map((r, i) => (
                    <span key={i} className='badge badge-light-success fw-bolder fs-8 me-2'>
                      {initialdata?.roles.filter((role: any) => role.id == r)[0]?.role}
                    </span>
                  ))}
            </div>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div>

      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div> */}

      <Languages />
      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a
          className='menu-link px-5'
          onClick={() => {
            dispatch(setAuthState({user: null, token: ''}))
          }}
        >
          {intl.formatMessage({id: 'FORM.BUTTON.SIGNOUT'})}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
