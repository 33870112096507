// createApi
import api from '..'

// paths
import {ACCOUNT, ACCOUNT_LOG_OUT, ACCOUNT_AVATAR, ACCOUNT_UPDATE_PASSWORD} from '../paths'

// helpers - serializeQueryArgs
import serializeQueryArgs from '../helpers/serializeQueryArgs'

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    account: builder.mutation({
      query: (credentials) => ({
        url: ACCOUNT,
        method: 'GET',
      }),
    }),
    updateAccount: builder.mutation({
      query: (credentials) => ({
        url: ACCOUNT,
        method: 'PUT',
        body: credentials.endpointData,
      }),
    }),
    updatePassword: builder.mutation({
      query: (credentials) => ({
        url: ACCOUNT_UPDATE_PASSWORD,
        method: 'PUT',
        body: credentials.endpointData,
      }),
    }),
    updateAvatar: builder.mutation({
      query: (credentials) => ({
        url: ACCOUNT_AVATAR,
        method: 'POST',
        body: credentials.endpointData,
      }),
    }),

    logout: builder.mutation({
      query: (credentials) => ({
        url: ACCOUNT_LOG_OUT,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useAccountMutation,
  useUpdateAvatarMutation,
  useUpdateAccountMutation,
  useUpdatePasswordMutation,
  useLogoutMutation,
} = extendedApi
