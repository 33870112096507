// createApi
import api from '..'

// helpers - serializeQueryArgs
import serializeQueryArgs from '../helpers/serializeQueryArgs'
import filterQueryUrlCreater from '../helpers/filterQueryUrlCreater'

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    notifications: builder.query({
      query: (credentials) => ({
        url: filterQueryUrlCreater({
          baseUrl: `/notifications/`,
          endpointData: credentials.endpointData,
        }),
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: ['NOTIFICATION'],
    }),
    updateNotification: builder.mutation({
      query: (credentials) => ({
        url: `/notifications/${credentials.endpointData.id}/`,
        method: 'PUT',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: ['NOTIFICATION'],
    }),
    massNotifications: builder.query({
      query: (credentials) => ({
        url: filterQueryUrlCreater({
          baseUrl: `/mass-notifications/`,
          endpointData: credentials.endpointData,
        }),
       
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: ['MASS_NOTIFICATION'],
    }),
    addMassNotification: builder.mutation({
      query: (credentials) => ({
        url: `/mass-notifications/create`,
        method: 'POST',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: ['MASS_NOTIFICATION'],
    }),
    notificationsMarkAll: builder.query({
      query: (credentials) => ({
        url:`/notifications/?mark_all=1`,
        method: 'GET',
      }),
      serializeQueryArgs,
      invalidatesTags: ['NOTIFICATION'],
    }),
  }),
})

export const {
  useNotificationsQuery,
  useLazyNotificationsQuery,
  useUpdateNotificationMutation,
  useMassNotificationsQuery,
  useAddMassNotificationMutation,
  useLazyNotificationsMarkAllQuery
} = extendedApi
