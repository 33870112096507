//React
import React, {useEffect, useRef} from 'react'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

//Helpers
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'

type Props = {
  className: string
  title: string
  bgPath: string
  number_of_guests: string | number
  remaining_time_to_birthday: string | number
  number_of_packages: string | number
}

const CustomerDetail: React.FC<Props> = ({
  className,
  title,
  bgPath,
  number_of_guests,
  remaining_time_to_birthday,
  number_of_packages,
}) => {
  const intl = useIntl()

  console.log(remaining_time_to_birthday)

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}

      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Chart */}
        <div
          className={`d-flex flex-column justify-content-center align-items-center card-rounded h-250px `}
          style={{
            backgroundImage: bgPath,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <span className='display-5 d-block text-center text-white'>
            {intl.formatMessage({id: 'DASHBOARD.CUSTOMER_DETAIL.TITLE'})}
          </span>
        </div>
        {/* end::Chart */}
        {/* begin::Stats */}
        <div className='card-p position-relative' style={{marginTop: -100}}>
          {/* begin::Row */}
          <div className='row'>
            {/* begin::Col */}
            <div className='col-5'>
              <div className='row'>
                {/* begin::Col */}
                <div className='rounded-2 mb-7 p-5' style={{backgroundColor: '#F9F9F9'}}>
                  <span className='display-5 fw-semibold d-block text-center text-gray-700'>
                    {number_of_guests}
                  </span>
                  <span className='d-block fw-semibold fs-3 text-center text-muted'>
                    {intl.formatMessage({
                      id: 'DASHBOARD.CUSTOMER_DETAIL.NUMBER_OF_CUSTOMER_WITH_REFERENCES',
                    })}
                  </span>
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='rounded-2 p-5' style={{backgroundColor: '#F9F9F9'}}>
                  <span className='display-5 fw-semibold d-block text-center text-gray-700'>
                    {number_of_guests}
                  </span>
                  <span className='d-block fw-semibold fs-3 text-center text-muted'>
                    {intl.formatMessage({
                      id: 'DASHBOARD.CUSTOMER_DETAIL.NUMBER_OF_GUEST_WITH_REFERENCES',
                    })}
                  </span>
                </div>
                {/* end::Col */}
              </div>
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-7 d-flex justify-content-center align-items-center'>
              <div
                className='rounded-2 p-5 h-200px align-self-center'
                style={{backgroundColor: '#F9F9F9'}}
              >
                <span
                  className='display-2 fw-semibold d-block text-center'
                  style={{color: '#3E97FF'}}
                >
                  {remaining_time_to_birthday}
                </span>
                <span className='d-block fw-semibold fs-3 text-center ' style={{color: '#3E97FF'}}>
                  {intl.formatMessage({id: 'DASHBOARD.CUSTOMER_DETAIL.DAY_UNTIL_BIRTHDAY'})}
                </span>
              </div>
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
          {/* begin::Row */}

          {/* end::Row */}
        </div>
        {/* end::Stats */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {CustomerDetail}
