//react-router-dom
import {Link} from 'react-router-dom'

//Helpers
import {KTIcon} from '../../../../../_metronic/helpers'

//Date Format
import {dateFormat} from '../../../../i18n/Metronici18n'

//Data Fsn
import {differenceInDays} from 'date-fns'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

type BodyanAlysisProps = {
  created_at: string
  next_measurement: string
  pass_or_remaning_time: number
}

type Props = {
  className: string
  body_analysis: BodyanAlysisProps[] | undefined
}

const MeasurementDates: React.FC<Props> = ({className, body_analysis}) => {

  const intl = useIntl()
  return(
  <div className={`card ${className}`}>
    {/*begin::Header*/}
    <div className='card-header border-0'>
      <h3 className='card-title fw-bold text-gray-900'>{intl.formatMessage({id: 'DASHBOARD.MEASUREMENT.TITLE'})}</h3>
    </div>

    {/*end::Header*/}

    {/*begin::Body*/}
    <div className='card-body pt-0'>
      {body_analysis &&
        body_analysis.map((n, i) => (
          <div
            key={i}
            className={`d-flex align-items-center bg-light-${
              differenceInDays(new Date(n.next_measurement), new Date()) > 0 ? 'warning' : 'danger'
            } rounded p-5 mb-7`}
          >
            <KTIcon
              iconName={'abstract-48'}
              className={`text-${
                differenceInDays(new Date(n.next_measurement), new Date()) > 0
                  ? 'warning'
                  : 'danger'
              } me-5 fs-1 text-${
                differenceInDays(new Date(n.next_measurement), new Date()) > 0
                  ? 'warning'
                  : 'danger'
              }`}
            />

            {/*begin::Title*/}
            <div className='flex-grow-1 me-2'>
              <Link
                to='/crafted/widgets/lists'
                className='fw-bolder text-gray-800 text-hover-primary fs-6'
              >
                {dateFormat(new Date(n.next_measurement), 'dd.MM.yyyy')}
              </Link>

              <span className='text-muted fw-bold d-block'>
                {dateFormat(new Date(n.created_at), 'dd.MM.yyyy')}
              </span>
            </div>
            {/*end::Title*/}

            {/*begin::Lable*/}
            <span
              className={`fw-bolder text-${
                differenceInDays(new Date(n.next_measurement), new Date()) > 0
                  ? 'warning'
                  : 'danger'
              } py-1`}
            >
              {differenceInDays(new Date(n.next_measurement), new Date())}
            </span>
            {/*end::Lable*/}
          </div>
        ))}
    </div>

    {/*end::Body*/}
  </div>
)}

export {MeasurementDates}
