import {useEffect} from 'react'
import {
  DrawerComponent,
  MenuComponent,
  ScrollComponent,
  ToggleComponent,
} from '../../../../assets/ts/components'
import {Tab1Menu} from '../Tab1Menu'
import {Tab1MenuCustomer} from '../Tab1MenuCustomer'

//Redux - Dispatch - Actions - State
import {useDispatch, useSelector} from 'react-redux'
import {
  setAuthState,
  selectCurrentUser,
  selectToken,
} from '../../../../../app/redux/slice/authSlice'
import {selectInitialData} from '../../../../../app/redux/slice/initialDataSlice'
import {setInitialData} from '../../../../../app/redux/slice/initialDataSlice'

const Tab1 = () => {
  const currentUser = useSelector(selectCurrentUser)

  const is_superuser = currentUser?.is_superuser
  const is_staff = currentUser?.is_staff
  const is_customer = currentUser?.is_customer

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
    }, 50)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])
  return (
    <div
      className='menu menu-column menu-fit menu-rounded menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold fs-5 px-6 my-5 my-lg-0'
      id='kt_aside_menu'
      data-kt-menu='true'
    >
      <div id='kt_aside_menu_wrapper' className='menu-fit'>
        {is_superuser === true || is_staff === true ? <Tab1Menu /> : null}
      
        {is_customer === true ? <Tab1MenuCustomer /> : null}
      </div>
    </div>
  )
}

export {Tab1}
