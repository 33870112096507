/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import clsx from 'clsx'
import {useThemeMode} from '../../../../_metronic/partials'
import {dateFormat} from '../../../i18n/Metronici18n'
//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

type Props = {
  numberOfLectureByDateData: object | undefined
  className: string
  svgIcon: string
  color: string
  change: string
  description: string
}

const NumberOfLectureByDate: React.FC<Props> = ({
  numberOfLectureByDateData,
  className,
  svgIcon,
  color,
  change,
  description,
}) => {
  const intl = useIntl()
  const [chartData, setChartData] = useState({
    data: [] as number[],
    categories: [] as string[],
  })
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))
    const labelColor = getCSSVariableValue('--bs-gray-800')
    const baseColor = getCSSVariableValue('--bs-' + color)
    const lightColor = getCSSVariableValue('--bs-' + color + '-light')

    console.log(height)
    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, labelColor, baseColor, lightColor, chartData, intl)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, color, mode, chartData, intl])

  useEffect(() => {
    if (numberOfLectureByDateData != undefined) {
      let data: number[] = []
      let categories: string[] = []

      Object.keys(numberOfLectureByDateData).forEach(function (key) {
        data.push(numberOfLectureByDateData[key] as number)
        categories.push(dateFormat(new Date(key), 'dd MMM yy'))
      })

      setChartData({
        data,
        categories,
      })
    }
  }, [numberOfLectureByDateData])

  return (
    <div className={`card ${className}`}>
      <div className={`card-header border-0  py-5`}>
        <h3 className='card-title fw-bold text-dark'>
          {intl.formatMessage({id: 'DASHBOARD.THE_NUMBER_OF_LECTURES_CONDUCTED_BY_DATES.TITLE'})}
        </h3>
      </div>
      {/* begin::Body */}
      <div className='card-body p-0'>
        <div ref={chartRef} className='card-rounded-bottom' style={{height: '170px'}}></div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {NumberOfLectureByDate}

function getChartOptions(
  height: number,
  labelColor: string,
  baseColor: string,
  lightColor: string,
  chartData,
  intl
): ApexOptions {
  return {
    series: [
      {
        name: intl.formatMessage({
          id: 'DASHBOARD.THE_NUMBER_OF_LECTURES_CONDUCTED_BY_DATES.NUMBER_OF_LECTURES',
        }),
        data: chartData.data,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: height,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: chartData.categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: '#E4E6EF',
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: 0,
      max: 60,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  }
}
