import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
} from 'date-fns'

import {dateFormat} from '../i18n/Metronici18n'

interface CustomProps {
  intl: {
    formatMessage: (message: {id: string}) => string
  }
  range: {
    startDate: Date | null
    endDate: Date | null
  }
  onlyDate?: boolean
}

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
}

const dateRangeName = (props: CustomProps) => {
  const {intl, range, onlyDate = false} = props
  if (onlyDate) {
    if (range.startDate == null || range.endDate == null) {
      return intl.formatMessage({id: 'DATE.SELECT_DATE_RANGE'})
    } else {
      return `${dateFormat(range.startDate, 'dd/MM/yyyy')} - ${dateFormat(
        range.endDate,
        'dd/MM/yyyy'
      )}`
    }
  }
  if (range.startDate == null || range.endDate == null)
    return intl.formatMessage({id: 'DATE.SELECT_DATE_RANGE'})
  else if (
    isSameDay(range.startDate, defineds.startOfToday) &&
    isSameDay(range.endDate, defineds.endOfToday)
  ) {
    return intl.formatMessage({id: 'DATE.TODAY'})
  } else if (
    isSameDay(range.startDate, defineds.startOfYesterday) &&
    isSameDay(range.endDate, defineds.endOfYesterday)
  ) {
    return intl.formatMessage({id: 'DATE.YESTERDAY'})
  } else if (
    isSameDay(range.startDate, defineds.startOfWeek) &&
    isSameDay(range.endDate, defineds.endOfWeek)
  ) {
    return intl.formatMessage({id: 'DATE.THIS_WEEK'})
  } else if (
    isSameDay(range.startDate, defineds.startOfLastWeek) &&
    isSameDay(range.endDate, defineds.endOfLastWeek)
  ) {
    return intl.formatMessage({id: 'DATE.LAST_WEEK'})
  } else if (
    isSameDay(range.startDate, defineds.startOfMonth) &&
    isSameDay(range.endDate, defineds.endOfMonth)
  ) {
    return intl.formatMessage({id: 'DATE.THIS_MONTH'})
  } else if (
    isSameDay(range.startDate, defineds.startOfLastMonth) &&
    isSameDay(range.endDate, defineds.endOfLastMonth)
  ) {
    return intl.formatMessage({id: 'DATE.LAST_MONTH'})
  } else {
    return `${dateFormat(range.startDate, 'dd/MM/yyyy')} - ${dateFormat(
      range.endDate,
      'dd/MM/yyyy'
    )}`
  }
}

export default dateRangeName
