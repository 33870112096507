// createApi
import api from '..'

// paths
import {INITIALDATA} from '../paths'

// helpers - serializeQueryArgs
import serializeQueryArgs from '../helpers/serializeQueryArgs'

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    initialdata: builder.mutation({
      query: (credentials) => ({
        url: INITIALDATA,
        method: 'GET',
      }),
    }),
  }),
})

export const {useInitialdataMutation} = extendedApi
