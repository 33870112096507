//React
import React, {FC, useState, useEffect, useRef, RefObject} from 'react'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

//CLSX - Dynamic CSS Class Creator
import clsx from 'clsx'

//React Router Dom
import {useNavigate} from 'react-router-dom'

//Typeahead
import {Typeahead} from 'react-bootstrap-typeahead'

//React Hook Form - Yup Validation
import {useForm, Controller} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'

//react-phone-number-input
import {CustomPhoneInput} from '../../../i18n/Metronici18n'
import {isValidPhoneNumber} from 'react-phone-number-input'

//Redux ToolKit API
import {useCreateCustormerMutation} from '../../../api/endpoints/customer'
import {useStaffsQuery} from '../../../api/endpoints/staff'
import {useCustormersQuery} from '../../../api/endpoints/customer'
import {useCheckUsernameMutation} from '../../../api/endpoints/auth'
import {useCompanyQuery} from '../../../api/endpoints/company'

//Redux - Dispatch - Actions - State
import {useDispatch, useSelector} from 'react-redux'
import {selectToken, selectCurrentUser, setAuthState} from '../../../redux/slice/authSlice'
import {selectInitialData} from '../../../redux/slice/initialDataSlice'

//Helper
import {KTIcon} from '../../../../_metronic/helpers'

//Model
import {Facility, Option} from '../../../models'

const FormModal: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const closeButtonRef: RefObject<HTMLButtonElement> = useRef(null)
  const initialdata = useSelector(selectInitialData)
  const [request, {isLoading, isError, data}] = useCreateCustormerMutation()
  const [requestCheckUsername] = useCheckUsernameMutation()
  const {data: companyInformationData} = useCompanyQuery({
    endpointData: {},
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })
  const formSchema = Yup.object().shape({
    username: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    first_name: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    last_name: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    email: Yup.string()
      .email(intl.formatMessage({id: 'FORM.VALIDATION.EMAIL'}))
      .required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    password: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    phone_number: Yup.string().test(
      'phoneValues',
      intl.formatMessage({id: 'FORM.VALIDATION.PHONE_NUMBER'}),
      function (phoneValues) {
        if (!phoneValues) {
          return false
        } else {
          return isValidPhoneNumber(phoneValues)
        }
      }
    ),
    gender: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    birth_of_date: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    role: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    ref_id: Yup.array(),
    ref_type: Yup.string(),
  })

  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    reset,
    control,
    clearErrors,
    formState: {errors, touchedFields, isSubmitting, isValid},
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      first_name: '',
      last_name: '',
      username: '',
      email: '',
      gender: '',
      password: '',
      birth_of_date: '',
      role: '',
      ref_type: '',
      ref_id: [] as Option[],
    },
    resolver: yupResolver(formSchema),
  })

  const onSubmit = (formData: any) => {
    const sendFormdata = new FormData()

    

    Object.keys(formData).forEach(function (key) {
      if (key == 'ref_type' || key == 'ref_id') {
      } else {
        if (typeof formData[key] === 'object') {
          formData[key].map((x: any) => {
            sendFormdata.append(key, String(x.id))
          })
        } else {
          sendFormdata.append(key, formData[key])
        }
      }
    })

    if (formData.role == '3' && formData.ref_type != '' && formData.ref_id.length > 0) {
      sendFormdata.append(
        'reference',
        JSON.stringify({ref_type: formData.ref_type, ref_id: formData.ref_id[0]?.id})
      )
    } else {
      sendFormdata.append('reference', JSON.stringify({}))
    }

    request({
      endpointData: sendFormdata,
      toastSuccessMessageStatus: true,
      toastErrorMessageStatus: true,
    })
  }

  const watchAllFields = watch()

  useEffect(() => {
    if (!isLoading && !isError && data !== undefined) {
      navigate(`/customer-management/customer/${data?.data?.id}/profil`)
      reset()
      if (closeButtonRef.current) {
        closeButtonRef.current.click()
      }
    }
  }, [isLoading, isError, data])

  const {data: staffData} = useStaffsQuery({
    endpointData: {
      limit: -1,
    },
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })

  const {data: customerData} = useCustormersQuery({
    endpointData: {
      limit: -1,
    },
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })

  const watchUserNameField = watch('username', '')

  const controlUserName = async () => {
    const sendFormdata = new FormData()
    sendFormdata.append('username', watchUserNameField)
    try {
      const res = await requestCheckUsername({
        endpointData: sendFormdata,
        toastSuccessMessageStatus: false,
        toastErrorMessageStatus: false,
      }).unwrap()
      clearErrors('username')
    } catch (error) {
      const typedError = error as {data?: {message?: string; data?: string}}

      setError(
        'username',
        {type: 'custom', message: `${typedError?.data?.message} - ${typedError?.data?.data}`},
        {shouldFocus: true}
      )
    }
  }

  return (
    <div className='modal fade' id='modal_create_customer' aria-hidden='true'>
      {/* begin::Modal dialog */}
      <div className='modal-dialog modal-dialog-centered mw-1000px'>
        {/* begin::Modal content */}
        <div className='modal-content'>
          <div className='modal-header'>
            {/* begin::Modal title */}
            <h2 className='fw-bolder'>
              {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT.NEW_CUSTOMER_TITLE'})}
            </h2>
            {/* end::Modal title */}

            {/* begin::Close */}
            <button
              ref={closeButtonRef}
              className='btn btn-icon btn-sm btn-active-icon-primary'
              data-bs-dismiss='modal'
              style={{cursor: 'pointer'}}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
            {/* end::Close */}
          </div>
          {/* begin::Modal body */}
          <div className='modal-body scroll-y mx-5 my-7'>
            <form
              id='modal_create_customer_form'
              className='form'
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              {/* begin::Scroll */}
              <div
                className='d-flex flex-column scroll-y me-n7 pe-7 px-2'
                id='modal_create_customer_scroll'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#modal_create_customer_header'
                data-kt-scroll-wrappers='#modal_create_customer_scroll'
                data-kt-scroll-offset='300px'
              >
                <div className='row'>
                  <div className='col-6'>
                    <div className='fv-row mb-7'>
                      {/* begin::Label */}
                      <label
                        className='required fw-bold fs-6 mb-2 w-100'
                        style={{textAlign: 'left'}}
                      >
                        {intl.formatMessage({id: 'FORM.LABEL.FIRST_NAME'})}
                      </label>
                      {/* end::Label */}

                      {/* begin::Input */}
                      <input
                        type='text'
                        {...register('first_name')}
                        placeholder={''}
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {'is-invalid': touchedFields?.first_name && errors?.first_name},
                          {
                            'is-valid': touchedFields?.first_name && !errors?.first_name,
                          }
                        )}
                      />
                      {touchedFields?.first_name && errors?.first_name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors?.first_name?.message}</span>
                          </div>
                        </div>
                      )}

                      {/* end::Input */}
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='fv-row mb-7'>
                      {/* begin::Label */}
                      <label
                        className='required fw-bold fs-6 mb-2 w-100'
                        style={{textAlign: 'left'}}
                      >
                        {intl.formatMessage({id: 'FORM.LABEL.LAST_NAME'})}
                      </label>
                      {/* end::Label */}

                      {/* begin::Input */}
                      <input
                        type='text'
                        {...register('last_name')}
                        placeholder={''}
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {'is-invalid': touchedFields?.last_name && errors?.last_name},
                          {
                            'is-valid': touchedFields?.last_name && !errors?.last_name,
                          }
                        )}
                      />
                      {touchedFields?.last_name && errors?.last_name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors?.last_name?.message}</span>
                          </div>
                        </div>
                      )}

                      {/* end::Input */}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <div className='fv-row mb-7'>
                      {/* begin::Label */}
                      <label
                        className='required fw-bold fs-6 mb-2 w-100'
                        style={{textAlign: 'left'}}
                      >
                        {intl.formatMessage({id: 'FORM.LABEL.USERNAME'})}
                      </label>
                      {/* end::Label */}

                      {/* begin::Input */}

                      <Controller
                        name={'username'}
                        control={control}
                        defaultValue=''
                        render={({field}) => (
                          <input
                            type='text'
                            {...field}
                            placeholder={''}
                            className={clsx(
                              'form-control form-control-solid mb-3 mb-lg-0',
                              {'is-invalid': touchedFields?.username && errors?.username},
                              {
                                'is-valid': touchedFields?.username && !errors?.username,
                              }
                            )}
                            onBlur={() => {
                              field.onBlur()
                              controlUserName()
                            }}
                          />
                        )}
                      />

                      {touchedFields?.username && errors?.username && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors?.username?.message}</span>
                          </div>
                        </div>
                      )}

                      {/* end::Input */}
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='fv-row mb-7'>
                      {/* begin::Label */}
                      <label
                        className='required fw-bold fs-6 mb-2 w-100'
                        style={{textAlign: 'left'}}
                      >
                        {intl.formatMessage({id: 'FORM.LABEL.PASSWORD'})}
                      </label>
                      {/* end::Label */}

                      {/* begin::Input */}
                      <input
                        type='text'
                        {...register('password')}
                        placeholder={''}
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {'is-invalid': touchedFields?.password && errors?.password},
                          {
                            'is-valid': touchedFields?.password && !errors?.password,
                          }
                        )}
                      />
                      {touchedFields?.password && errors?.password && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors?.password?.message}</span>
                          </div>
                        </div>
                      )}

                      {/* end::Input */}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-6'>
                    <div className='fv-row mb-7'>
                      {/* begin::Label */}
                      <label
                        className='required fw-bold fs-6 mb-2 w-100'
                        style={{textAlign: 'left'}}
                      >
                        {intl.formatMessage({id: 'FORM.LABEL.EMAIL'})}
                      </label>
                      {/* end::Label */}

                      {/* begin::Input */}
                      <input
                        type='email'
                        {...register('email')}
                        placeholder={''}
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {'is-invalid': touchedFields?.email && errors?.email},
                          {
                            'is-valid': touchedFields?.email && !errors?.email,
                          }
                        )}
                      />
                      {touchedFields?.email && errors?.email && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors?.email?.message}</span>
                          </div>
                        </div>
                      )}

                      {/* end::Input */}
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='fv-row mb-7'>
                      {/* begin::Label */}
                      <label
                        className='required fw-bold fs-6 mb-2 w-100'
                        style={{textAlign: 'left'}}
                      >
                        {intl.formatMessage({id: 'FORM.LABEL.PHONE_NUMBER'})}
                      </label>
                      {/* end::Label */}

                      {/* begin::Input */}
                      <Controller
                        control={control}
                        name='phone_number'
                        render={({field}) => (
                          <CustomPhoneInput
                            {...field}
                            defaultCountry={
                              companyInformationData?.data[0]?.country
                                ? companyInformationData?.data[0]?.country
                                : null
                            }
                            numberInputProps={{
                              className: clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': touchedFields?.phone_number && errors?.phone_number},
                                {
                                  'is-valid': touchedFields?.phone_number && !errors?.phone_number,
                                }
                              ),
                            }}
                          />
                        )}
                      />
                      {touchedFields?.phone_number && errors?.phone_number && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors?.phone_number?.message}</span>
                          </div>
                        </div>
                      )}

                      {/* end::Input */}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-6'>
                    <div className='fv-row mb-7'>
                      {/* begin::Label */}
                      <label
                        className='required fw-bold fs-6 mb-2 w-100'
                        style={{textAlign: 'left'}}
                      >
                        {intl.formatMessage({id: 'FORM.LABEL.GENDER'})}
                      </label>
                      {/* end::Label */}

                      {/* begin::Input */}
                      <select
                        {...register('gender')}
                        autoComplete='off'
                        className={clsx('form-control form-select form-control-solid mb-3 mb-lg-0')}
                      >
                        <option value={''}>
                          {intl.formatMessage({id: 'FORM.PLACEHOLDER.SELECT'})}
                        </option>
                        {initialdata?.gender &&
                          initialdata?.gender.map((opt: any, index: number) => (
                            <option key={index} value={opt.id}>
                              {`${opt?.name}`}
                            </option>
                          ))}
                      </select>
                      {touchedFields?.gender && errors?.gender && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors?.gender?.message}</span>
                          </div>
                        </div>
                      )}

                      {/* end::Input */}
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='fv-row mb-7'>
                      {/* begin::Label */}
                      <label
                        className='required fw-bold fs-6 mb-2 w-100'
                        style={{textAlign: 'left'}}
                      >
                        {intl.formatMessage({id: 'FORM.LABEL.BIRTH_OF_DATE'})}
                      </label>
                      {/* end::Label */}

                      {/* begin::Input */}
                      <input
                        type='date'
                        {...register('birth_of_date')}
                        placeholder={''}
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {'is-invalid': touchedFields?.birth_of_date && errors?.birth_of_date},
                          {
                            'is-valid': touchedFields?.birth_of_date && !errors?.birth_of_date,
                          }
                        )}
                      />
                      {touchedFields?.birth_of_date && errors?.birth_of_date && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors?.birth_of_date?.message}</span>
                          </div>
                        </div>
                      )}

                      {/* end::Input */}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      {/* begin::Label */}
                      <label
                        className='required fw-bold fs-6 mb-2 w-100'
                        style={{textAlign: 'left'}}
                      >
                        {intl.formatMessage({id: 'FORM.LABEL.ROLE'})}
                      </label>
                      {/* end::Label */}

                      {/* begin::Input */}
                      <Controller
                        control={control}
                        name='role'
                        render={({field: {onChange, onBlur, value, ref, name}}) => (
                          <select
                            ref={ref}
                            onBlur={onBlur}
                            value={value}
                            onChange={(event) => {
                              onChange(event)
                              setValue('ref_type', '')
                              setValue('ref_id', [])
                            }}
                            autoComplete='off'
                            className={clsx(
                              'form-control form-select form-control-solid mb-3 mb-lg-0'
                            )}
                          >
                            <option value=''>
                              {intl.formatMessage({id: 'FORM.PLACEHOLDER.SELECT'})}
                            </option>
                            {initialdata &&
                              initialdata.user_roles &&
                              initialdata.user_roles
                                .filter((rol) => rol.id == 2 || rol.id == 3)
                                .map((opt: any, index: number) => (
                                  <option key={index} value={opt.id}>
                                    {opt.role}
                                  </option>
                                ))}
                          </select>
                        )}
                      />

                      {touchedFields?.role && errors?.role && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors?.role?.message}</span>
                          </div>
                        </div>
                      )}

                      {/* end::Input */}
                    </div>
                  </div>
                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      {/* begin::Label */}
                      <label className='fw-bold fs-6 mb-2 w-100' style={{textAlign: 'left'}}>
                        {intl.formatMessage({id: 'FORM.LABEL.REFERENCE_TYPE'})}
                      </label>
                      {/* end::Label */}

                      {/* begin::Input */}
                      <Controller
                        control={control}
                        name='ref_type'
                        render={({field: {onChange, onBlur, value, ref, name}}) => (
                          <select
                            ref={ref}
                            onBlur={onBlur}
                            value={value}
                            onChange={(event) => {
                              onChange(event)

                              setValue('ref_id', [])
                            }}
                            autoComplete='off'
                            className={clsx(
                              'form-control form-select form-control-solid mb-3 mb-lg-0'
                            )}
                            disabled={watchAllFields.role == '3' ? false : true}
                          >
                            <option value={''}>
                              {intl.formatMessage({id: 'FORM.PLACEHOLDER.SELECT'})}
                            </option>
                            <option value={'0'}>
                              {intl.formatMessage({id: 'FORM.OPTION.STAFF'})}
                            </option>
                            <option value={'1'}>
                              {intl.formatMessage({id: 'FORM.OPTION.CUSTOMER'})}
                            </option>
                          </select>
                        )}
                      />

                      {touchedFields?.ref_type && errors?.ref_type && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors?.ref_type?.message}</span>
                          </div>
                        </div>
                      )}

                      {/* end::Input */}
                    </div>
                  </div>
                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      {/* begin::Label */}
                      <label className='fw-bold fs-6 mb-2 w-100' style={{textAlign: 'left'}}>
                        {intl.formatMessage({id: 'FORM.LABEL.REFERENCE'})}
                      </label>
                      {/* end::Label */}

                      {/* begin::Input */}
                      <Controller
                        control={control}
                        name='ref_id'
                        render={({field: {onChange, onBlur, value, ref, name}}) => (
                          <Typeahead
                            id='modal_create_customer_ref_id'
                            clearButton
                            placeholder={intl.formatMessage({id: 'FORM.PLACEHOLDER.SELECT'})}
                            dropup
                            //multiple
                            labelKey={'name'}
                            isValid={
                              touchedFields[name] != undefined &&
                              touchedFields[name] &&
                              (errors[name] == undefined || !errors[name])
                            }
                            isInvalid={
                              touchedFields[name] != undefined && errors[name] != undefined
                            }
                            options={
                              watchAllFields.ref_type == '0'
                                ? staffData?.data?.data == undefined
                                  ? []
                                  : staffData?.data?.data.map((staff) => ({
                                      id: staff.id,
                                      name: `${staff.first_name} ${staff.last_name}`,
                                    }))
                                : watchAllFields.ref_type == '1'
                                ? staffData?.data?.data == undefined
                                  ? []
                                  : customerData?.data?.data.map((customer) => ({
                                      id: customer.id,
                                      name: `${customer.first_name} ${customer.last_name}`,
                                    }))
                                : []
                            }
                            onChange={(value) => {
                              onChange(value)
                            }}
                            onBlur={onBlur}
                            selected={value as Option[]}
                            emptyLabel={intl.formatMessage({id: 'FORM.WARNING.EMPTY_LABEL'})}
                            disabled={watchAllFields.role == '3' ? false : true}
                          />
                        )}
                      />
                      {touchedFields?.ref_type && errors?.ref_type && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors?.ref_type?.message}</span>
                          </div>
                        </div>
                      )}

                      {/* end::Input */}
                    </div>
                  </div>
                </div>
              </div>
              {/* end::Scroll */}

              {/* begin::Actions */}

              <div className='text-center pt-15'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={isLoading}
                >
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'FORM.BUTTON.SAVE'})}
                  </span>
                  {isLoading && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({id: 'MESSAGE.PLEASE_WAITING'})}{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              {/* end::Actions */}
            </form>
          </div>
          {/* end::Modal body */}
        </div>
        {/* end::Modal content */}
      </div>
      {/* end::Modal dialog */}
    </div>
  )
}

export default FormModal
